import { Routes, Route } from "react-router-dom";
import GolfEvent_2018 from "./GolfEvent_2018";
import GolfEvent_2019 from "./GolfEvent_2019";
import GolfEvent_2020 from "./GolfEvent_2020";
import Directory from "./Directory";

// Nested switch to route the correct path of gallery without App.js's knowledge
export default function Gallery() {
  return (
    <Routes>
      <Route path="/" element={<Directory />} />
      <Route path="/2018_golf_event" element={<GolfEvent_2018 />} />
      <Route path="/2019_golf_event" element={<GolfEvent_2019 />} />
      <Route path="/2020_golf_event" element={<GolfEvent_2020 />} />
    </Routes>
  );
}
