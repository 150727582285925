import {
  SET_CURRENT_USER,
  GET_USER_DASHBOARD,
  SUCCESSFUL_REGISTRATION,
} from "../actions/types";
import isEmpty from "../../validation/is-empty";

const initialState = {
  isAuthenticated: false,
  newRegister: false,
  dashboard: {},
  user: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
        newRegister: false,
      };
    case SUCCESSFUL_REGISTRATION:
      return {
        ...state,
        newRegister: true,
      };
    case GET_USER_DASHBOARD:
      return {
        ...state,
        dashboard: action.payload,
      };
    default:
      return state;
  }
}
