import React, { Component } from "react";
import { Container, Item, Header, Divider, Image } from "semantic-ui-react";

export default function COFGolfPros() {
  const golfers = [
    {
      childKey: "sullivan",
      image: "/images/pro_golfers/sullivan.2.jpg",
      header: "Ryan Sullivan",
      quote:
        "“Being a part of something special like the Cindy O’Donnell Foundation is near & dear to my heart because it helps find a cure to a nasty disease which affects so many people globally and breaks families apart...If my involvement to the Foundation in any way, shape or form helps to raise awareness or generate more conversation on the topic then I feel like we’ve accomplished our goal...special thanks to Patrick O’Donnell for reaching out and inviting me to join the team”",
      bio: "Ryan is a PGA Tour Player and wears the COF logo on his golf bag during the Tour. Ryan played in the 2019 US Open, he has also made several starts on the Web.com Tour. Ryan has also won on the Latinoamérica Tour.",
    },
    {
      childKey: "thadd",
      image: "/images/pro_golfers/thadd.1.jpg",
      header: "Thaddeus Obecny II",
      quote:
        '"It is so amazing to be a part of such a great foundation! I am very thankful for their help and support of my future plans in the golfing world!”',
      bio: "Thaddeus grew up in the Ohio Valley and is a graduate of Wheeling Park High School. Upon graduation he played golf at Coastal Carolina University where he was a one time conference champion. He boasts six top 5 finishes in his professional career along with 83 wins as an amateur since the age of five.",
    },
    {
      childKey: "wax",
      image: "/images/pro_golfers/wax.1.jpg",
      header: "Zack Wax",
      quote:
        '"The Cindy O’Donnell foundation means a lot to me! To be associated with a foundation and a team that is truly bigger than the game of golf is a great feeling. I take great pride in representing the foundation where ever the game of golf takes me."',
      bio: "Zack grew up in the Ohio Valley and is a graduate of River High School. Upon graduation he studied accounting at The Ohio State University. Zack has had solid success on mini tours and is dialing in on Monday qualifiers this year.",
    },
    {
      childKey: "shelby",
      image: "/images/pro_golfers/shelby.1.jpg",
      header: "Shelby Warner",
      quote:
        "I am very excited to be a part of the golf staff at the Cindy O’Donnell Foundation and support this wonderful organization that makes playing the game that much more meaningful!",
      bio: "Shelby is from Lima, a small town in northwest Ohio and currently based in New Orleans, LA. She graduated from Lima Central Catholic in 2013 and is a former student-athlete at the University of Findlay and aspiring to play professional golf on the LPGA Tour. She earned her MBA with a CPA Concentration in May of 2017 along with majors in Financial and Managerial Accounting. She was fortunate enough to work for a great company after graduation, Marathon Petroleum Corporation, as an accountant for over the course of a year. Feeling as if her athletic career was unfinished, she left the corporate setting in June, 2018 to pursue a career as a professional golfer. She now travels across the country playing on the WAPT Tour to prepare for 2021 LPGA Qualifying School.",
    },
  ];
  return (
    <Container text style={{ marginTop: "5%", marginBottom: "5%" }}>
      <Container style={{ textAlign: "center" }}>
        <Header as="h1">COF Golf Professionals</Header>
        <Image centered size="big" src="/images/pro_golfers/sullivan.1.jpg" />
      </Container>
      <Divider hidden />
      <Container style={{ textAlign: "huge", marginTop: "5%" }}>
        <Item.Group divided>
          {golfers.map((golfer) => {
            return (
              <Item
                id={golfer.childKey}
                key={golfer.childKey}
                style={{ textAlign: "center", fontSize: "1.1em" }}
              >
                <Item.Image size="medium" src={golfer.image} />
                <Item.Content>
                  <Item.Header>{golfer.header}</Item.Header>
                  <Item.Meta>{golfer.bio}</Item.Meta>
                  <Item.Description>{golfer.quote}</Item.Description>
                </Item.Content>
              </Item>
            );
          })}
        </Item.Group>
      </Container>
    </Container>
  );
}
