import axios from "axios";
import {
  STRIPE_RESET,
  GET_ERRORS,
  CLEAR_ERRORS,
  REMOVE_SECRET,
  DONATION_INTENT,
  SAVE_DONATION,
  GOLF_SIGNUP_INTENT,
  SAVE_GOLF_SIGNUP,
  GAMING_SIGNUP_INTENT,
  SAVE_GAMING_SIGNUP,
  STEAKFRY_SIGNUP_INTENT,
  SAVE_STEAKFRY_SIGNUP,
  STRIPE_REQUEST_BEGIN,
  STRIPE_REQUEST_END,
} from "./types";

// Reset stripe state and clear errors
export const clearStripeState = () => (dispatch) => {
  dispatch({
    type: STRIPE_RESET,
  });

  dispatch({
    type: CLEAR_ERRORS,
  });
};

/*
 * Donations
 */

// Create a new donation intent
export const createDonationIntent = (donationData) => (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });

  dispatch({
    type: REMOVE_SECRET,
  });

  dispatch({
    type: STRIPE_REQUEST_BEGIN,
  });

  axios
    .post("/api/stripe/donation_intent", donationData)
    .then((response) => {
      dispatch({
        type: DONATION_INTENT,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });

  dispatch({
    type: STRIPE_REQUEST_END,
  });
};

// Save the donation data after completing transaction
export const saveDonation = (donationData) => (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });

  axios
    .post("/api/stripe/save_donation", donationData)
    .then((response) => {
      dispatch({
        type: SAVE_DONATION,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

/*
 * Golf
 */

// Create a new golf signup intent
export const createGolfSignupIntent = (signUpData) => (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });

  dispatch({
    type: REMOVE_SECRET,
  });

  dispatch({
    type: STRIPE_REQUEST_BEGIN,
  });

  axios
    .post("/api/stripe/golf_signup_intent", signUpData)
    .then((response) => {
      dispatch({
        type: GOLF_SIGNUP_INTENT,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });

  dispatch({
    type: STRIPE_REQUEST_END,
  });
};

// Save the golf signup data after completing transaction
export const saveGolfSignup = (signUpData) => (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });

  axios
    .post("/api/stripe/save_golf_signup", signUpData)
    .then((response) => {
      dispatch({
        type: SAVE_GOLF_SIGNUP,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

/*
 * Steak Fry
 */

// Create a new steak fry signup intent
export const createSteakFrySignupIntent = (signUpData) => (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });

  dispatch({
    type: REMOVE_SECRET,
  });

  dispatch({
    type: STRIPE_REQUEST_BEGIN,
  });

  axios
    .post("/api/stripe/steakfry_signup_intent", signUpData)
    .then((response) => {
      dispatch({
        type: STEAKFRY_SIGNUP_INTENT,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });

  dispatch({
    type: STRIPE_REQUEST_END,
  });
};

// Save the steak fry signup data after completing transaction
export const saveSteakFrySignup = (signUpData) => (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });

  axios
    .post("/api/stripe/save_steakfry_signup", signUpData)
    .then((response) => {
      dispatch({
        type: SAVE_STEAKFRY_SIGNUP,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

/*
 * Gaming
 */

// Create a new gaming signup intent
export const createGamingSignupIntent = (signUpData) => (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });

  dispatch({
    type: REMOVE_SECRET,
  });

  dispatch({
    type: STRIPE_REQUEST_BEGIN,
  });

  axios
    .post("/api/stripe/gaming_signup_intent", signUpData)
    .then((response) => {
      dispatch({
        type: GAMING_SIGNUP_INTENT,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });

  dispatch({
    type: STRIPE_REQUEST_END,
  });
};

// Save the gaming signup data after completing transaction
export const saveGamingSignup = (signUpData) => (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });

  axios
    .post("/api/stripe/save_gaming_signup", signUpData)
    .then((response) => {
      dispatch({
        type: SAVE_GAMING_SIGNUP,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
