// Layout
export const SET_MOBILE = "SET_MOBILE";

// User
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SUCCESSFUL_REGISTRATION = "SUCCESSFUL_REGISTRATION";
export const GET_USER_DASHBOARD = "GET_DASHBOARD";

// Content
export const GET_SITE_CONTENT = "GET_SITE_CONTENT";

// Stripe
export const DONATION_INTENT = "DONATION_INTENT";
export const SAVE_DONATION = "SAVE_DONATION";
export const GOLF_SIGNUP_INTENT = "GOLF_SIGNUP_INTENT";
export const SAVE_GOLF_SIGNUP = "SAVE_GOLF_SIGNUP";
export const GAMING_SIGNUP_INTENT = "GAMING_SIGNUP_INTENT";
export const SAVE_GAMING_SIGNUP = "SAVE_GAMING_SIGNUP";
export const STEAKFRY_SIGNUP_INTENT = "STEAKFRY_SIGNUP_INTENT";
export const SAVE_STEAKFRY_SIGNUP = "SAVE_STEAKFRY_SIGNUP";
export const STRIPE_REQUEST_BEGIN = "STRIPE_REQUEST_BEGIN";
export const STRIPE_REQUEST_END = "STRIPE_REQUEST_END";
export const REMOVE_SECRET = "REMOVE_SECRET";
export const STRIPE_RESET = "STRIPE_RESET";

// Events
export const GET_UPCOMING_EVENTS = "GET_UPCOMING_EVENTS";
export const GET_EVENTS = "GET_EVENTS";
export const GET_EVENT = "GET_EVENT";

// Errors
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const GET_ERRORS = "GET_ERRORS";
