import axios from "axios";
import setAuthToken from "../../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import {
  GET_ERRORS,
  CLEAR_ERRORS,
  GET_USER_DASHBOARD,
  SET_CURRENT_USER,
  SUCCESSFUL_REGISTRATION,
} from "./types";

// Register User
export const registerUser = (userData) => (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });

  axios
    .post("/api/user/register", userData)
    .then((response) => {
      dispatch({
        type: SUCCESSFUL_REGISTRATION,
        payload: response.body,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Login User (get user token)
export const loginUser = (userData) => (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });

  axios
    .post("/api/user/login", userData)
    .then((response) => {
      const { token } = response.data;

      // Save to local storage
      localStorage.setItem("jwtToken", token);

      // Set token to auth header
      setAuthToken(token);

      // Decode token to get user data
      const decoded = jwt_decode(token);

      // Set current user
      dispatch(setCurrentUser(decoded));
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set the current user to an empty object which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};

// Get dashboard information
export const getUserDashboard = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });

  axios
    .get("/api/user/dashboard")
    .then((response) => {
      dispatch({
        type: GET_USER_DASHBOARD,
        payload: response.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response,
      })
    );
};
