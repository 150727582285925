import React, { Component } from 'react'
import { Container, Header } from 'semantic-ui-react'
import ImageGallery from 'react-image-gallery'
import { golf_photos_2019 } from './photos'
import 'react-image-gallery/styles/css/image-gallery.css'

export default class GolfEvent_2019 extends Component {
  render () {
    return (
      <Container
        text
        textAlign='left'
        style={{ marginTop: '5%', marginBottom: '5%' }}
      >
        <Header as='h1'>Gallery</Header>
        <Header as='h3'>2019 Golf Event</Header>
        <ImageGallery items={golf_photos_2019} />
      </Container>
    )
  }
}
