import {
  REMOVE_SECRET,
  DONATION_INTENT,
  SAVE_DONATION,
  GAMING_SIGNUP_INTENT,
  SAVE_GAMING_SIGNUP,
  STEAKFRY_SIGNUP_INTENT,
  SAVE_STEAKFRY_SIGNUP,
  GOLF_SIGNUP_INTENT,
  SAVE_GOLF_SIGNUP,
  STRIPE_REQUEST_BEGIN,
  STRIPE_REQUEST_END,
  STRIPE_RESET,
} from "../actions/types";

const initialState = {
  sendingRequest: false,
  clientSecret: "",
  savedIntent: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DONATION_INTENT:
      return {
        ...state,
        clientSecret: action.payload.clientSecret,
        savedIntent: action.payload.intent,
      };
    case SAVE_DONATION:
      return { ...state };
    case GAMING_SIGNUP_INTENT:
      return {
        ...state,
        clientSecret: action.payload.clientSecret,
        savedIntent: action.payload.intent,
      };
    case SAVE_GAMING_SIGNUP:
      return { ...state };
    case STEAKFRY_SIGNUP_INTENT:
      return {
        ...state,
        clientSecret: action.payload.clientSecret,
        savedIntent: action.payload.intent,
      };
    case SAVE_STEAKFRY_SIGNUP:
      return { ...state };
    case GOLF_SIGNUP_INTENT:
      return {
        ...state,
        clientSecret: action.payload.clientSecret,
        savedIntent: action.payload.intent,
      };
    case SAVE_GOLF_SIGNUP:
      return { ...state };
    case STRIPE_REQUEST_BEGIN:
      return {
        ...state,
        sendingRequest: true,
      };
    case STRIPE_REQUEST_END:
      return {
        ...state,
        sendingRequest: false,
      };
    case REMOVE_SECRET:
      return {
        ...state,
        clientSecret: "",
      };
    case STRIPE_RESET: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
}
