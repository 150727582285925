import { useState } from "react";
import { Container, Grid } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import NavBarMobile from "./Mobile/index";
import NavBarDesktop from "./Desktop/index";

export default function NavBar({ children }) {
  const authenticated = useSelector((state) => state.auth.isAuthenticated);
  const [visible, setVisible] = useState(false);
  const links = [
    // A link with children is for a drop down on desktop
    {
      content: "Home",
      key: "home",
      href: "/",
    },
    {
      content: "About",
      key: "about",
      children: [
        {
          content: "COF",
          key: "cof",
          href: "/cof",
        },
        {
          content: "Family",
          key: "family",
          href: "/family",
        },
        {
          content: "Staff",
          key: "staff",
          href: "/staff",
        },
        {
          content: "Golf Pros",
          key: "golfers",
          href: "/golfers",
        },
      ],
    },
    {
      content: "Events",
      key: "events",
      href: "/events",
    },
    {
      content: "Scholarship",
      key: "scholarship",
      href: "/scholarship",
    },
    {
      content: "Family of the Year",
      key: "familyoftheyear",
      href: "/family_of_the_year",
    },
    {
      content: "Gallery",
      key: "gallery",
      href: "/gallery",
    },
    {
      content: "Sponsors",
      key: "sponsors",
      href: "/sponsors",
    },
    {
      content: "My Dashboard",
      key: "dashboard",
      href: "/dashboard",
    },
    {
      content: "Register",
      key: "register",
      href: "/register",
    },
    {
      content: "Log in",
      key: "login",
      href: "/login",
    },
    {
      content: "Log out",
      key: "logout",
      href: "/logout",
    },
  ];

  // Adds any object with an href to a new array because
  // mobile doesn't have a dropdown for children.
  const mobileLinks = [];
  links.forEach((link) => {
    if (link.href) {
      mobileLinks.push(link);
    } else {
      link.children.forEach((c) => {
        mobileLinks.push(c);
      });
    }
  });

  const NavBarChildren = ({ children }) => (
    <Container fluid> {children} </Container>
  );

  const handlePusher = () => {
    if (visible) {
      setVisible(false);
    }
  };

  return (
    <div className="nav">
      <Grid>
        <Grid.Row columns={1} only="mobile tablet" style={{ padding: "0px" }}>
          <Grid.Column>
            <NavBarMobile
              onPusherClick={handlePusher}
              visible={visible}
              links={mobileLinks
                .filter((l) => {
                  if (!authenticated) {
                    if (l.key !== "logout" && l.key !== "dashboard") {
                      return true;
                    }
                  } else {
                    if (l.key !== "login" && l.key !== "register") {
                      return true;
                    }
                  }
                })
                .map((l) => {
                  return l;
                })}
            >
              <NavBarChildren> {children} </NavBarChildren>
            </NavBarMobile>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1} only="computer" style={{ padding: "0px" }}>
          <Grid.Column>
            <NavBarDesktop
              links={links
                .filter((l) => {
                  if (!authenticated) {
                    if (l.key !== "logout" && l.key !== "dashboard") {
                      return true;
                    }
                  } else {
                    if (l.key !== "login" && l.key !== "register") {
                      return true;
                    }
                  }
                })
                .map((l) => {
                  return l;
                })}
            >
              <NavBarChildren> {children} </NavBarChildren>
            </NavBarDesktop>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}
