import React from "react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import {
  Input,
  Message,
  Divider,
  Container,
  Checkbox,
  Icon,
  Button,
  Form,
  Radio,
} from "semantic-ui-react";
import {
  saveSteakFrySignup,
  createSteakFrySignupIntent,
} from "../../../../../../redux/actions/stripeActions";
import { objIsEmpty } from "../../../../../../utils";
import "./index.css";

export default function SignUpForm({ closeModal }) {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const events = useSelector((state) => state.events);
  const errors = useSelector((state) => state.errors);
  const signup = useSelector((state) => state.signup);
  const stripes = useSelector((state) => state.stripe);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [eventNumber, setEventNumber] = useState(0);
  const [eventDescription, setEventDescription] = useState("");
  const [sendingRequest, setSendingRequest] = useState(false);
  const [receiptEmail, setReceiptEmail] = useState("");
  const [amount, setAmount] = useState("25.00");
  const [attendees, setAttendees] = useState([
    {
      name: "", // attendee-0-name
      allergies: "", // attendee-0-allergies
      child: false, // attendee-0-child
    },
  ]);

  const cardStyle = {
    style: {
      base: {
        iconColor: "#FFF",
        color: "#FFF",
        fontSize: "14px",
        fontSmoothing: "antialiased",
        "::placeholder": {
          color: "#a8a8a8",
        },
      },
      invalid: {
        color: "#e5424d",
        ":focus": {
          color: "red",
        },
      },
    },
  };

  const handleChange = (e) => {
    e.preventDefault();
    let [_, key, name] = e.target.id.split("-");
    let tmpAttendees = [...attendees];
    tmpAttendees[key][name] = e.target.value;
    setAttendees(tmpAttendees);
  };

  const handleAddAttendee = (e) => {
    e.preventDefault();
    let tmpAttendees = [
      ...attendees,
      {
        name: "",
        allergies: "",
        child: false,
      },
    ];
    setAmount(Number(amount) + 25.0);
    setAttendees(tmpAttendees);
  };

  const handleRemoveAttendee = (e) => {
    e.preventDefault();
    if (attendees.length > 1) {
      let isChild = attendees[attendees.length - 1].child;
      let tmpAttendees = attendees.slice(0, -1);
      setAmount(isChild ? Number(amount) - 10.0 : Number(amount) - 25.0);
      setAttendees(tmpAttendees);
    }
  };

  const toggleAge = (e) => {
    let tmpAttendees = [...attendees];
    let [_, key, __] = e.target.id.split("-");
    tmpAttendees[key]["child"] = !attendees[key]["child"];
    setAttendees(tmpAttendees);
    setAmount(
      attendees[key].child ? Number(amount) - 15.0 : Number(amount) + 15.0
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSendingRequest(true);

    if (!stripe || !elements) {
      return;
    }

    if (
      objIsEmpty(stripes.savedIntent) ||
      stripes.savedIntent.amount != amount ||
      stripes.savedIntent.receiptEmail != receiptEmail ||
      JSON.stringify(stripes.savedIntent.attendees) !==
        JSON.stringify(attendees)
    ) {
      dispatch(
        createSteakFrySignupIntent({
          auth,
          amount: Number(amount).toFixed(2),
          eventNumber,
          eventDescription,
          receiptEmail,
          attendees,
        })
      );
    }

    setError(null);
  };

  useEffect(() => {
    if (auth.isAuthenticated) {
      setReceiptEmail(auth.user.email);
    }

    if (events.event) {
      setEventNumber(events.event.eventNumber);
      setEventDescription(events.event.description);
    }

    async function confirmPayment() {
      const payload = await stripe.confirmCardPayment(stripes.clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });

      if (payload.error) {
        setError(payload.error.message);
      } else {
        dispatch(
          saveSteakFrySignup({
            auth,
            amount: Number(amount).toFixed(2),
            eventNumber,
            eventDescription,
            receiptEmail,
            attendees,
            token: stripes.clientSecret,
          })
        );
        setSuccess(true);
      }

      setSendingRequest(false);
    }

    if (!objIsEmpty(errors)) {
      setSuccess(false);
      setSendingRequest(false);
    }

    if (stripes.clientSecret) {
      confirmPayment();
    }
  }, [stripes, errors, error]);

  return (
    <Container fluid>
      <Divider inverted horizontal>
        Attendees
      </Divider>
      {attendees.length > 0 &&
        attendees.map((attendee, key) => {
          const name = "Attendee " + (key + 1).toString() + " Name";
          const allergies = "Attendee " + (key + 1).toString() + " Allergies";
          const id = `attendee-${key}-name`;
          return (
            <div key={key}>
              {errors.auth && <Message error content={errors["auth"]} />}
              {errors[id] && <Message error content={errors[id]} />}
              <div className="team-sign-up-grouped-element">
                <Input
                  id={`attendee-${key}-name`}
                  icon="add user"
                  iconPosition="left"
                  onChange={handleChange}
                  value={attendees[key]["name"]}
                  placeholder={name}
                  transparent
                  inverted
                  fluid
                />
                <Divider inverted />
                <Input
                  id={`attendee-${key}-allergies`}
                  icon="food"
                  iconPosition="left"
                  onChange={handleChange}
                  value={attendees[key]["allergies"]}
                  placeholder={allergies}
                  transparent
                  inverted
                  fluid
                />
                <Divider inverted />
                <Checkbox
                  id={`attendee-${key}-child`}
                  onChange={toggleAge}
                  checked={attendee.child}
                  label={{
                    children: (
                      <p
                        style={{
                          color: attendee.child ? "#ffffff" : "gray",
                        }}
                      >
                        Age is 12 or younger
                      </p>
                    ),
                  }}
                  style={{
                    color: "#ffffff",
                  }}
                />
              </div>
            </div>
          );
        })}
      <Container
        fluid
        textAlign="center"
        style={{
          marginTop: "5%",
          display: "flex",
          justifyContent: "center",
          marginBottom: "5%",
        }}
      >
        <Button
          inverted
          size="tiny"
          color="blue"
          onClick={handleAddAttendee}
          disabled={attendees.length >= 10 ? true : false}
        >
          <Icon name="plus" />
          Add attendee
        </Button>
        <Button
          inverted
          size="tiny"
          color="red"
          onClick={handleRemoveAttendee}
          disabled={attendees.length == 1 ? true : false}
        >
          <Icon name="minus" />
          Remove attendee
        </Button>
      </Container>
      <Divider inverted horizontal>
        Payment
      </Divider>
      {errors.amount && <Message error content={errors["amount"]} />}
      {errors.card && <Message error content={errors["card"]} />}
      {errors.receiptEmail && (
        <Message error content={errors["receiptEmail"]} />
      )}
      {error && <Message error content={error} />}
      <div className="team-sign-up-element">
        <Input
          name="amount"
          icon="usd"
          iconPosition="left"
          onChange={handleChange}
          value={Number(amount).toFixed(2)}
          transparent
          hidden
          disabled
          inverted
          fluid
        />
      </div>
      <div className="team-sign-up-element">
        <CardElement id="card-element" options={cardStyle} />
      </div>
      <div className="team-sign-up-element">
        <Input
          name="receiptEmail"
          icon="mail"
          iconPosition="left"
          onChange={handleChange}
          placeholder="Email to send receipt to"
          value={receiptEmail}
          transparent
          inverted
          fluid
        />
      </div>
      <Divider />
      {errors.server && (
        <Message
          error
          color="orange"
          content={`Contact Admin (${errors.code}) - ${errors.server}`}
        />
      )}
      {Object.keys(errors).length > 0 && (
        <Message error content={"Please fix the error(s) above."} />
      )}
      {success && (
        <Message color="green" content="Success! Thank you for signing up!" />
      )}
      <div style={{ textAlign: "center" }}>
        <Button
          color="green"
          size="tiny"
          loading={!!sendingRequest}
          disabled={!!(sendingRequest || success)}
          onClick={handleSubmit}
          inverted
        >
          <Icon name="checkmark" /> Confirm
        </Button>
        <Button color="red" size="tiny" onClick={closeModal} inverted>
          <Icon name="cancel" /> {success ? "Close" : "Not now"}
        </Button>
      </div>
    </Container>
  );
}
