import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../../../redux/actions/authActions";
import { Container, Header, Icon, Button, Divider } from "semantic-ui-react";

export default function Logout() {
  const authenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    authenticated ? dispatch(logoutUser()) : navigate("/login");
  }, []);

  return (
    <Container
      text
      textAlign="center"
      style={{ marginTop: "5%", marginBottom: "2.5%" }}
    >
      <Header as="h1" icon>
        <Icon circular color="blue" name="hand peace outline" />
        <Divider />
        <Header.Subheader>Successfully logged out</Header.Subheader>
        <Divider />
        <Button color="blue" size="medium" onClick={() => navigate("/")}>
          Go home
        </Button>
      </Header>
    </Container>
  );
}
