import { Container, Header, Button, Divider, Item } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function Directory() {
  const errors = useSelector((state) => state.errors);
  const gallery_directory = [
    // TODO Admin Page
    {
      event: "2018 Golf Event",
      link: "/gallery/2018_golf_event",
      location: "Belmont Hills Country Club",
      description: "The Cindy O'Donnell Foundation's 1st Inaugural Golf Outing",
      date: "Saturday September 22nd, 2018",
      image: "/images/gallery/golf_event_2018/22.jpg",
    },
    {
      event: "2019 Golf Event",
      link: "/gallery/2019_golf_event",
      location: "Belmont Hills Country Club",
      description: "The Cindy O'Donnell Foundation's 2nd Annual Golf Outing",
      date: "Saturday August 17th, 2019",
      image: "/images/gallery/golf_event_2019/12.jpg",
    },
    {
      event: "2020 Golf Event",
      link: "/gallery/2020_golf_event",
      location: "Belmont Hills Country Club",
      description: "The Cindy O'Donnell Foundation's 3rd Annual Golf Outing",
      date: "Saturday August 22nd, 2020",
      image: "/images/gallery/golf_event_2020/1.jpg",
    },
  ];

  return (
    <Container
      textAlign="center"
      text
      style={{ marginTop: "5%", marginBottom: "5%" }}
    >
      <Header as="h1">Gallery</Header>
      <Item.Group divided>
        {gallery_directory.map((gallery, key) => {
          return (
            <Item key={key}>
              <Container textAlign="center">
                <Item.Image size="medium" src={gallery.image} />
              </Container>
              <Item.Content>
                <Divider hidden />
                <Item.Header>{gallery.event}</Item.Header>
                <Item.Meta>@ {gallery.location}</Item.Meta>
                <Item.Description>
                  {gallery.description} held on{" "}
                  <span style={{ fontStyle: "italic" }}>{gallery.date}</span>!
                </Item.Description>
                <div style={{ marginTop: "2.5%" }}>
                  <Link to={gallery.link}>
                    <Button
                      size="small"
                      color="blue"
                      icon="picture"
                      content="Enter gallery"
                    />
                  </Link>
                </div>
              </Item.Content>
            </Item>
          );
        })}
      </Item.Group>
    </Container>
  );
}
