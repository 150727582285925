import { Container, Item, List, Divider } from "semantic-ui-react";

export default function FamilyOfTheYear() {
  return (
    <Container text style={{ marginTop: "5%", marginBottom: "5%" }}>
      <Container style={{ textAlign: "center", marginTop: "5%" }}>
        <Item.Image
          size="large"
          src="/images/home/carousel/family-of-the-year.jpg"
        />
      </Container>
      <Container style={{ textAlign: "center", marginTop: "5%" }}>
        <Item>
          <Item.Content>
            <Item.Header as="h2">
              The Cindy O’Donnell Foundation's Family of the Year
            </Item.Header>
            <Item.Description
              style={{
                marginTop: "2.5%",
                marginBottom: "1.5%",
              }}
            >
              <p>
                The Cindy O’Donnell Foundation is pleased to announce the
                creation of the Cindy O’Donnell Family of the Year fund in honor
                of the late Cindy O’Donnell. Each year, one family from the Ohio
                Valley will be selected to receive a financial gift in an effort
                to provide a better future for those affected by circumstance.
              </p>
            </Item.Description>
          </Item.Content>
        </Item>
        <br />
        <h2>Awarded Families</h2>
        <Item.Group>
          <Item id="stjohn-family">
            <Item.Image
              size="medium"
              src="/images/family-of-the-year/codey.jpg"
            />
            <Item.Content>
              <Item.Header>2023, The St. John Family</Item.Header>
              <Item.Description>
                When Codey was just a few months into kindergarten, he was
                diagnosed with Neuroblastoma. For the past 17 years his family
                has had to go to NYC every 3 months to evaluate his cancer and
                he often had to undergo surgeries and additional treatments in
                between the trips. He has been told 15 times that the cancer has
                returned. This led to Codeys current intestinal complications he
                has today. He lost his father a few years ago and lives in
                Bellaire, OH with his mother and caretaker, Amy. He's grown up
                missing school, field trips, camps, and playing sports to
                instead learn how to live as a warrior and survivor. He dreams
                of the day he will become cancer free and can start his career
                as a culinary chef. His favorite pastimes are smoking meats and
                cooking for his family. We ask that you please take time out of
                your day send thoughts, prayers, and donate to The St. John
                family in support of our 2023{" "}
                <span style={{ fontStyle: "italic" }}>Family Of The Year</span>.
              </Item.Description>
            </Item.Content>
          </Item>
          <br />
          <Item id="redman-family">
            <Item.Image
              size="medium"
              src="/images/family-of-the-year/jaymison.jpg"
            />
            <Item.Content>
              <Item.Header>2022, The Redman Family</Item.Header>
              <Item.Description>
                Bill and Cirsten’s son, Jaymison, was diagnosed with a rare
                stage 4 brain cancer called Pineoblastoma. Since, he’s endured 3
                brain surgeries including a full craniotomy, 6 weeks of
                radiation to his brain and spine and 4 months of intravenous
                chemotherapy. He’s been treated in 3 different states. He’s been
                life flighted, had 3 emergency ambulance rides to Pittsburgh
                Children’s and 1 emergency visit. He is currently stable and
                being treated holistically with herbs and stress therapy, but
                his condition is lifelong. He’s 11 and resides in Wheeling, WV.
                We ask that you please take time out of your day send thoughts,
                prayers, and donate to The Redman family in support of our 2022{" "}
                <span style={{ fontStyle: "italic" }}>Family Of The Year</span>.
                <List>
                  <List.Item style={{ fontSize: "1.25rem" }}>
                    <List.Content>
                      <a
                        href={
                          "https://www.gofundme.com/f/2022-family-of-the-year?qid=66e047edb748868c64ac21b68a295bd2"
                        }
                      >
                        Click to donate on GoFundMe!
                      </a>
                    </List.Content>
                  </List.Item>
                </List>
              </Item.Description>
            </Item.Content>
          </Item>
          <br />
          <Item id="vinka-family">
            <Item.Image
              size="medium"
              src="/images/family-of-the-year/vivvy.2.jpg"
            />
            <Item.Content>
              <Item.Header>2021, The Vinka Family</Item.Header>
              <Item.Description>
                Vivvy Vinka is a sweet baby girl from Martins Ferry, OH
                diagnosed with a Congenital Diaphragmatic Hernia. CDH causes a
                hole in the diaphragm that allows organs to move and grow in the
                chest keeping the baby’s lungs from developing before birth. Due
                to how severe her hernia was she was born and treated at
                Children’s Hospital of Philadelphia before coming home at 6
                months. She is on oxygen full time and uses a feeding tube to
                eat. An event is in place to support Vivvy and her family and we
                ask that you please take time out of your day send thoughts,
                prayers, and donate to The Vinka family in support of our 2021{" "}
                <span style={{ fontStyle: "italic" }}>Family Of The Year</span>.
                <List>
                  <List.Item style={{ fontSize: "1.25rem" }}>
                    <List.Content>
                      <a
                        href={
                          "https://www.gofundme.com/f/vivvy-vinkas-cdh-fund?utm_source=customer&utm_medium=copy_link&utm_campaign=p_cf+share-flow-1"
                        }
                      >
                        Click to donate on GoFundMe!
                      </a>
                    </List.Content>
                  </List.Item>
                </List>
              </Item.Description>
            </Item.Content>
          </Item>
          <br />
          <Item id="bruce-family">
            <Item.Image
              size="medium"
              src="/images/family-of-the-year/teddy.2.jpg"
            />
            <Item.Content>
              <Item.Header>2020, The Bruce Family</Item.Header>
              <Item.Description>
                Teddy Bruce is a one year old boy from Bellaire, OH that was
                diagnosed with Metopic Craniosynostosis when he was born.
                Metopic Craniosynostosis prevents the skull from growing
                properly, and in most cases not leaving enough room for the
                brain to grow. If not corrected, it can cause brain pressure,
                learning disabilities, eye problems, autism, and other
                disabilities. He received a successful skull reconstruction
                surgery on June 18, 2020. We have an event in place to support
                Teddy and his family and ask that you please take time out of
                your day send thoughts, prayers, and donate to The Bruce family
                in support of our 2020{" "}
                <span style={{ fontStyle: "italic" }}>Family Of The Year</span>.
                <List>
                  <List.Item style={{ fontSize: "1.25rem" }}>
                    <List.Content>
                      <a
                        href={
                          "https://www.gofundme.com/f/1vvzwmmvk0?sharetype=teams&member=3618056&utm_medium=sms&utm_source=customer&utm_campaign=p_na+share-sheet&pc_code=sms_db_co2876_v1&rcid=4a09733cb10043058e45d60c900f869f"
                        }
                      >
                        Click to donate on GoFundMe!
                      </a>
                    </List.Content>
                  </List.Item>
                </List>
              </Item.Description>
            </Item.Content>
          </Item>
        </Item.Group>
      </Container>
    </Container>
  );
}
