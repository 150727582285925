import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getEvent } from "../../../../redux/actions/eventActions.js";
import { clearStripeState } from "../../../../redux/actions/stripeActions.js";
import GolfModal from "./GolfModal";
import GamingModal from "./GamingModal";
import SteakFryModal from "./SteakFryModal";
import {
  Container,
  Statistic,
  Button,
  Item,
  Message,
  List,
  Image,
  Header,
  Divider,
} from "semantic-ui-react";

export default function Event() {
  const errors = useSelector((state) => state.errors);
  const event = useSelector((state) => state.events.event);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(getEvent(params.number));
  }, []);

  return (
    <Container textAlign="left" style={{ marginTop: "5%", marginBottom: "5%" }}>
      {Object.keys(event).length != 0 ? (
        <EventItemGenerator event={event} />
      ) : (
        <Container
          textAlign="left"
          style={{ marginTop: "5%", marginBottom: "5%" }}
        >
          <Header as="h1">Event {params.number} doesn't exist.</Header>
        </Container>
      )}
    </Container>
  );
}

function EventItemGenerator({ event }) {
  const eventItem = [];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authenticated = useSelector((state) => state.auth.isAuthenticated);
  const [closed, setClosed] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [noAuthClick, setNoAuthClick] = useState(false);
  const openModal = () => {
    authenticated ? setModalOpen(true) : setNoAuthClick(true);
  };
  const closeModal = () => {
    setModalOpen(false);
    setClosed(true);
  };

  useEffect(() => {
    if (closed) {
      dispatch(clearStripeState());
      setClosed(false);
    }
  }, [closed]);

  switch (event.eventType) {
    case "Golf Event":
      eventItem.push(
        <GolfModal modalOpen={modalOpen} closeModal={() => closeModal()} />
      );
      eventItem.push(
        <Container textAlign="center">
          <Item.Image size="huge" src={event.image} style={{}} />
        </Container>
      );
      eventItem.push(
        <div style={{ textAlign: "center", marginTop: "5%" }}>
          <Link to="..">
            <Button size="large" color="red" icon="arrow left" content="Back" />
          </Link>
          {event.active ? (
            <Button
              onClick={openModal}
              size="large"
              color="blue"
              icon="golf ball"
              content="Sign up"
            />
          ) : (
            <Button
              disabled
              size="large"
              color="blue"
              icon="golf ball"
              content="Sign up closed"
            />
          )}
        </div>
      );
      eventItem.push(
        <div>
          <Divider />
          <Divider hidden />
          <Header>Partnership Opportunities</Header>
          <Image src={event.partnership_flyer} />
        </div>
      );
      break;
    case "Gaming Event":
      eventItem.push(
        <GamingModal modalOpen={modalOpen} closeModal={() => closeModal()} />
      );
      eventItem.push(
        <Container textAlign="center">
          <Item.Image size="medium" src="/images/cof_gaming_logo_camo.png" />
        </Container>
      );
      eventItem.push(
        <div style={{ textAlign: "center", marginTop: "5%" }}>
          <Link to="..">
            <Button size="large" color="red" icon="arrow left" content="Back" />
          </Link>
          {event.active ? (
            <Button
              onClick={openModal}
              size="large"
              color="blue"
              icon="game"
              content="Sign up"
            />
          ) : (
            <Button
              disabled
              size="large"
              color="blue"
              icon="game"
              content="Sign up closed"
            />
          )}
        </div>
      );
      eventItem.push(
        <div>
          <Divider />
          <Divider hidden />
          <Image src={event.rules_flyer} />
        </div>
      );
      break;
    case "Benefit Event":
      eventItem.push(
        <SteakFryModal modalOpen={modalOpen} closeModal={() => closeModal()} />
      );
      eventItem.push(
        <Container textAlign="center">
          <Item.Image size="medium" src="/images/logo.png" />
        </Container>
      );
      eventItem.push(
        <div style={{ textAlign: "center", marginTop: "5%" }}>
          <Link to="..">
            <Button size="large" color="red" icon="arrow left" content="Back" />
          </Link>
          {event.active ? (
            <Button
              onClick={openModal}
              size="large"
              color="blue"
              icon="food"
              content="Sign up"
            />
          ) : (
            <Button
              disabled
              size="large"
              color="blue"
              icon="food"
              content="Sign up closed"
            />
          )}
        </div>
      );
      eventItem.push(
        <div>
          <Divider />
          <Divider hidden />
          <Image src={event.partnership_flyer} />
        </div>
      );
      break;
    default:
      console.log("[*] Event type not accounted for.");
  }

  return (
    <Item>
      {eventItem.shift()}
      {eventItem.shift()}
      <Container text textAlign="center" style={{ marginTop: "5%" }}>
        <Item.Content style={{ marginBottom: "7.5%" }}>
          <Item.Header style={{ fontSize: "26px" }}>
            {event.eventType}
          </Item.Header>
          <Item.Meta>@ {event.location}</Item.Meta>
          {event.show_sponsor && (
            <Item.Image
              src={event.sponsor.logo}
              size="medium"
              style={{
                backgroundColor: "black",
              }}
            />
          )}
          <Item.Description
            style={{
              fontSize: "16px",
              marginTop: "2.5%",
              marginBottom: "1.5%",
            }}
          >
            {event.description} held on{" "}
            <span style={{ fontStyle: "italic" }}>{event.eventDate}</span>!
            {event.show_sponsor && (
              <Item.Extra style={{ marginTop: "2.5%" }}>
                <i>Sponsored by </i>
                <a href={event.sponsor.href}>{event.sponsor.name}</a>{" "}
              </Item.Extra>
            )}
            <Divider hidden />
            <Statistic.Group
              style={{ display: "flex", justifyContent: "center" }}
              size="mini"
              widths={2}
            >
              {event.stats.map((stat, key) => {
                return (
                  <Statistic key={key}>
                    <Statistic.Value>{stat.price}</Statistic.Value>
                    <Statistic.Label>{stat.text}</Statistic.Label>
                  </Statistic>
                );
              })}
            </Statistic.Group>
            <List horizontal size="large">
              {event.accomodations.map((accomodation, key) => {
                return (
                  <List.Item key={key}>
                    <List.Icon name={accomodation.icon} />
                    <List.Content>{accomodation.text}</List.Content>
                  </List.Item>
                );
              })}
            </List>
            <p style={{ marginTop: "5%", fontSize: "1.1em" }}>
              {event.proceeds}
            </p>
          </Item.Description>
          {/* Authorization error */}
          {noAuthClick && (
            <Message style={{ marginBottom: "3.5%" }} error>
              <Link to="/login"> Click here </Link>
              to login or signup.
            </Message>
          )}
          {eventItem.shift()}
        </Item.Content>
        {eventItem.shift()}
      </Container>
    </Item>
  );
}
