import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getUserDashboard } from "../../../redux/actions/authActions.js";
import {
  Container,
  Header,
  Image,
  Card,
  Icon,
  Grid,
  Item,
  Label,
  Segment,
  Button,
} from "semantic-ui-react";

export default function Dashboard() {
  const errors = useSelector((state) => state.errors);
  const authenticated = useSelector((state) => state.auth.isAuthenticated);
  const dashboard = useSelector((state) => state.auth.dashboard);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const date = new Date(user.date);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    authenticated ? dispatch(getUserDashboard()) : navigate("/login");
  }, [authenticated]);

  if (!authenticated) return null;

  return (
    <Container text style={{ marginTop: "5%", marginBottom: "5%" }}>
      <Header as="h1">My Dashboard</Header>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={8}>
            <Container text style={{ marginTop: "5%" }}>
              <Card color="blue">
                <Image src={user.avatar} size="medium" />
                <Card.Content>
                  <Card.Header>
                    {user.first
                      .toLowerCase()
                      .replace(/^\w/, (c) => c.toUpperCase())}{" "}
                    {user.last
                      .toLowerCase()
                      .replace(/^\w/, (c) => c.toUpperCase())}
                  </Card.Header>
                  <Card.Meta>
                    <span className="date">
                      Joined {monthNames[date.getMonth()]} {date.getDate()},{" "}
                      {date.getFullYear()}
                    </span>
                  </Card.Meta>
                  <Card.Description />
                </Card.Content>
                <Card.Content extra>
                  <Icon name="mail" />
                  {user.email}
                  <br />
                  <Icon name="user circle" />@{user.username}
                </Card.Content>
              </Card>
            </Container>
          </Grid.Column>

          <Grid.Column width={8}>
            <Header as="h2">Donations</Header>
            {Object.keys(dashboard).length > 0 &&
            dashboard.donations.length > 0 ? (
              dashboard.donations.map((donation, key) => {
                return (
                  <Item.Group as={Segment} color="blue" key={key}>
                    <Item>
                      <Item.Content>
                        <Icon name="dollar sign" />
                        <Item.Header>Amount</Item.Header>
                        <Item.Description>${donation.amount}</Item.Description>
                      </Item.Content>
                    </Item>
                    <Item>
                      <Item.Content>
                        <Icon name="chat" />
                        <Item.Header>Message</Item.Header>
                        <Item.Description>{donation.message}</Item.Description>
                      </Item.Content>
                    </Item>
                  </Item.Group>
                );
              })
            ) : (
              <i>No donations</i>
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            {Object.keys(dashboard).length > 0 ? (
              <Container fluid style={{ marginTop: "5%" }}>
                <Header as="h2">Events</Header>
                <Item.Group divided>
                  {dashboard.events_and_teams.length > 0 ? (
                    dashboard.events_and_teams.map((event_and_team, key) => {
                      const link = "/events/" + event_and_team.e.eventNumber;
                      const teamSignUp = new Date(event_and_team.t.date);
                      return (
                        <Item key={key}>
                          <Item.Image
                            size="medium"
                            src={event_and_team.e.image}
                          />
                          <Item.Content>
                            <Item.Header>{event_and_team.e.name}</Item.Header>
                            <Item.Meta>@ {event_and_team.e.location}</Item.Meta>
                            <Container>
                              <Icon name="group" />
                              {event_and_team.t.players.map((player, key) => {
                                return (
                                  player.name.length > 0 && (
                                    <Label key={key}>{player.name}</Label>
                                  )
                                );
                              })}
                              <br />
                              <i>
                                Signed up {monthNames[teamSignUp.getMonth()]}{" "}
                                {teamSignUp.getDate()},{" "}
                                {teamSignUp.getFullYear()}
                              </i>
                              <br />
                              <i>Amount paid: ${event_and_team.t.amount}</i>
                            </Container>
                            <Item.Description>
                              <Link to={link}>
                                <Button
                                  size="mini"
                                  color="blue"
                                  icon="list alternate outline"
                                  content="Details"
                                />
                              </Link>
                            </Item.Description>
                          </Item.Content>
                        </Item>
                      );
                    })
                  ) : (
                    <i>No events</i>
                  )}
                </Item.Group>
              </Container>
            ) : null}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
}
