import React from "react";
import { Grid, Segment, Icon, Divider, List } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Footer() {
  return (
    <React.Fragment>
      <Divider hidden />
      <Grid
        as={Segment}
        color="blue"
        padded
        stackable
        divided
        style={{
          color: "#FFF",
          marginBottom: "0px",
          backgroundColor: "#101010",
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
        }}
      >
        <Grid.Row>
          <Grid.Column width={6}>
            <p
              style={{ textAlign: "center", height: "1.5em", fontSize: "1em" }}
            >
              &copy; {new Date().getFullYear()} Cindy O'Donnell Foundation
              <br />
              501c3 Nonprofit of Minnesota
              <br />
              Federal Tax ID #84-3896105
              <br />
              <i> Est. 2018 | All Rights Reserved</i>
            </p>
          </Grid.Column>
          <Grid.Column
            verticalAlign="middle"
            style={{ textAlign: "center" }}
            width={4}
          >
            <FontAwesomeIcon icon="ribbon" size="5x" color="#2986CE" />{" "}
          </Grid.Column>
          <Grid.Column
            verticalAlign="middle"
            style={{ textAlign: "center" }}
            width={6}
          >
            <div style={{ textAlign: "center", fontSize: "1em" }}>
              <span style={{ height: "1.5em", display: "inline-block" }}>
                <Icon name="marker" /> 1311 Chardonnay Xing, Leander, TX 78641
              </span>
              <br />
              <span style={{ height: "1.5em", display: "inline-block" }}>
                <Icon name="mail" />
                <i>info@cindyofoundation.org</i>
              </span>
              <br />
              <span style={{ height: "1.5em", display: "inline-block" }}>
                <Icon name="phone" /> (740) 827-6720
              </span>
              <br />
              <div style={{ paddingTop: "2%" }}>
                <a
                  style={{ color: "#3b5998" }}
                  href="https://facebook.com/TheCindyOFoundation"
                >
                  <Icon link name="facebook" size="big" />
                </a>
                <a
                  style={{ color: "#cd486b" }}
                  href="https://instagram.com/TheCindyOFdtn"
                >
                  <Icon link name="instagram" size="big" />
                </a>
                <a
                  style={{ color: "#1DA1F2" }}
                  href="https://twitter.com/TheCindyOFdtn "
                >
                  <Icon link name="twitter" size="big" />
                </a>
              </div>
              <br />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </React.Fragment>
  );
}
