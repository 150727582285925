import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { loginUser } from "../../../redux/actions/authActions";
import {
  Container,
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
} from "semantic-ui-react";
import classnames from "classnames";

export default function Login() {
  const errors = useSelector((state) => state.errors);
  const authenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const newRegister = useSelector((state) => state.auth.newRegister);
  const dataInput = { email: setEmail, password: setPassword };
  const handleChange = (e) => {
    e.preventDefault();
    dataInput[e.target.name](e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(loginUser({ email, password }));
  };

  useEffect(() => {
    authenticated && navigate("/dashboard");
  }, [authenticated]);

  return (
    <Container text style={{ marginTop: "5%", marginBottom: "5% " }}>
      <Grid
        textAlign="center"
        style={{ height: "100%" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxHeight: 900, maxWidth: 650 }}>
          {errors.email && <Message error content={errors["email"]} />}
          {errors.password && <Message error content={errors["password"]} />}
          {newRegister && (
            <Message
              color="green"
              content="Successfully registered! You can now log in."
            />
          )}
          <Header as="h2" color="blue" textAlign="center">
            Log in to your profile
          </Header>
          <Form size="large" onSubmit={handleSubmit}>
            <Segment stacked>
              <Form.Input
                fluid
                icon="mail"
                iconPosition="left"
                placeholder="Email address"
                name="email"
                value={email}
                onChange={handleChange}
                className={classnames("", {
                  error: errors.email,
                })}
              />
              <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="Password"
                name="password"
                type="password"
                value={password}
                onChange={handleChange}
                className={classnames("", {
                  error: errors.password,
                })}
              />
              <Button color="blue" fluid size="large">
                Login
              </Button>
            </Segment>
          </Form>
          <Message>
            New to us? <Link to="/register">Sign Up</Link>
          </Message>
        </Grid.Column>
      </Grid>
    </Container>
  );
}
