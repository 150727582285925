import { GET_SITE_CONTENT } from "../actions/types";

const initialState = {
  home: {
    announcements: [],
    sticky: {
      active: false,
    },
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SITE_CONTENT:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
}
