import { useState } from "react";
import { Link } from "react-router-dom";
import { Icon, Menu, Sidebar, Segment, Item, Divider } from "semantic-ui-react";

export default function NavBarMobile({ links }) {
  const [visible, setVisible] = useState(false);
  const handleButtonClick = (e) => setVisible(!visible);
  const handleSidebarHide = (e) => setVisible(false);

  return (
    <div>
      <Segment
        inverted
        color={visible ? "black" : "blue"}
        onClick={handleButtonClick}
        style={{
          transition: "background-color .5s",
          margin: "0",
          textAlign: "center",
          borderRadius: "0em",
          height: "50px",
          borderTop: "2px solid #2986CE",
        }}
      >
        <Menu secondary compact>
          <Icon
            color={visible ? "blue" : null}
            style={{ transition: "background-color .5s" }}
            size="large"
            inverted
            name="sidebar"
            fitted
          />
        </Menu>
      </Segment>
      <Sidebar.Pushable>
        <Sidebar
          as={Menu}
          animation="push"
          icon="labeled"
          visible={visible}
          vertical
          direction="top"
          onHide={handleSidebarHide}
        >
          {links.map((link, key) => (
            <Item
              key={key}
              onClick={handleSidebarHide}
              as={Link}
              to={link.href}
            >
              {link.content}
            </Item>
          ))}
        </Sidebar>
        <Sidebar.Pusher
          style={
            visible
              ? { transition: "height .5s", height: "100vh" }
              : { transition: "height .5s", height: "0vh" }
          }
          dimmed={visible}
        />
      </Sidebar.Pushable>
      <Divider hidden />
    </div>
  );
}
