// React
import { useEffect } from "react";

// Pages
import Home from "./components/pages/Home";
import COF from "./components/pages/About/COF";
import Family from "./components/pages/About/Family";
import ProGolfers from "./components/pages/About/GolfPros";
import Staff from "./components/pages/About/Staff";
import Sponsors from "./components/pages/Sponsors";
import Events from "./components/pages/Events";
import Gallery from "./components/pages/Gallery";
import Scholarship from "./components/pages/Scholarship";
import FamilyOfTheYear from "./components/pages/FamilyOfTheYear";
import Dashboard from "./components/pages/Dashboard";
import NotFound from "./components/pages/NotFound";

// Authorization
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import Logout from "./components/auth/Logout";

// Router
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// Redux
import { getSiteContent } from "./redux/actions/contentActions";
import { setCurrentUser, logoutUser } from "./redux/actions/authActions";
import { Provider, useSelector, useDispatch } from "react-redux";
import store from "./redux/store.js";

// Layout
import DonateBar from "./components/layout/DonateBar";
import NavBar from "./components/layout/NavBar";
import Footer from "./components/layout/Footer";
import "semantic-ui-css/semantic.min.css";

// Font awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { faRibbon } from "@fortawesome/free-solid-svg-icons";

export default function App() {
  library.add(faRibbon);

  // Check for token in case of refresh
  if (localStorage.jwtToken) {
    // Set the auth token header
    setAuthToken(localStorage.jwtToken);

    // Decode token and get user info and expiration
    const decoded = jwt_decode(localStorage.jwtToken);

    // Set user and is authenticated
    store.dispatch(setCurrentUser(decoded));

    // Check for expired token
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      store.dispatch(logoutUser());
      window.location.href = "/login";
    }
  }

  useEffect(() => {
    store.dispatch(getSiteContent());
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <DonateBar />
          <NavBar />
          <Routes>
            {/* Home */}
            <Route exact path="/" element={<Home />} />

            {/* Pages */}
            <Route path="/cof" element={<COF />} />
            <Route path="/family" element={<Family />} />
            <Route path="/golfers" element={<ProGolfers />} />
            <Route path="/staff" element={<Staff />} />
            <Route path="/sponsors" element={<Sponsors />} />
            <Route path="/events/*" element={<Events />} />
            <Route path="/scholarship" element={<Scholarship />} />
            <Route path="/family_of_the_year" element={<FamilyOfTheYear />} />
            <Route path="/gallery/*" element={<Gallery />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="*" element={<NotFound />} />

            {/* Authorization */}
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/logout" element={<Logout />} />

            {/* Not Found */}
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </Provider>
  );
}
