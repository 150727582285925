import { useState } from "react";
import { useDispatch } from "react-redux";
import { Grid } from "semantic-ui-react";
import DonateBarMobile from "./Mobile/index";
import DonateBarDesktop from "./Desktop/index";
import DonateModal from "./DonateModal";
import { clearStripeState } from "../../../redux/actions/stripeActions";

export default function DonateBar() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const openDonationModal = () => setOpen(true);
  const closeDonationModal = () => {
    dispatch(clearStripeState());
    setOpen(false);
  };
  return (
    <div>
      <DonateModal open={open} closeDonationModal={closeDonationModal} />
      <Grid>
        <Grid.Row columns={1} only="mobile tablet">
          <Grid.Column>
            <DonateBarMobile openDonationModal={openDonationModal} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1} only="computer">
          <Grid.Column>
            <DonateBarDesktop openDonationModal={openDonationModal} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}
