import { useEffect, useState } from "react";
import { Container, Header, Image, Card, Segment } from "semantic-ui-react";
import { Gallery, ThumbnailImageProps } from "react-grid-gallery";

const ImageComponent = (props: ThumbnailImageProps) => {
  return (
    <img
      name={props.imageProps.name}
      src={props.imageProps.src}
      style={{
        height: "100%",
        width: "100%",
      }}
    />
  );
};

export default function Sponsors() {
  const [mobile, setMobile] = useState(true);
  const [width, setWidth] = useState(0);
  const titleSponsorWidth = mobile ? 400 : 600;
  const titleSponsorHeight = mobile ? 100 : 200;
  const sponsorWidth = mobile ? 10 : 400;
  const sponsorHeight = mobile ? 5 : 200;

  const titleSponsor = {
    name: "Raze International, Inc",
    logo: "images/sponsors/raze.png",
    href: "http://razeinternational.com",
  };

  const sponsors = [
    {
      name: "Ohio-West Virginia Excavating",
      href: "http://owvexcavating.com/",
      src: "/images/sponsors/ohio-wv.jpg",
      height: sponsorHeight,
      width: sponsorWidth,
    },
    {
      name: "Doan Ford",
      href: "https://www.doanford.com/",
      src: "/images/sponsors/doanford.png",
      height: sponsorHeight,
      width: sponsorWidth,
    },
    {
      name: "Caldwell Dentistry",
      href: "https://www.facebook.com/Caldwell-Dentistry-181338741906417/",
      src: "/images/sponsors/caldwell-dentistry.jpg",
      height: sponsorHeight,
      width: sponsorWidth,
    },
    {
      name: "Schumacher Law",
      href: "http://lschumacherlaw.com",
      src: "/images/sponsors/lslaw.png",
      height: sponsorHeight,
      width: sponsorWidth,
    },
    {
      name: "AccessQuip",
      href: "https://accessquip.com",
      src: "/images/sponsors/accessquip.jpg",
      height: sponsorHeight,
      width: sponsorWidth,
    },
    {
      name: "Wheeling Hospital",
      href: "https://wheelinghospital.org/",
      src: "/images/sponsors/wheelinghospital.jpg",
      height: sponsorHeight,
      width: sponsorWidth,
    },
    {
      name: "Matt's Tiger Pub",
      href: "http://mattstigerpub.com/",
      src: "/images/sponsors/tigerpub.jpg",
      height: sponsorHeight,
      width: sponsorWidth,
    },
    {
      name: "Premiums & Promotions",
      href: "https://premiumsandpromotions.com/",
      src: "/images/sponsors/premiumsandpromotions.jpg",
      height: sponsorHeight,
      width: sponsorWidth,
    },
    {
      name: "DeFelice Bros. Pizza",
      href: "http://eatdefelice.com/",
      src: "/images/sponsors/defelice.jpg",
      height: sponsorHeight,
      width: sponsorWidth,
    },
    {
      name: "Local 83",
      href: "http://www.ualocal83.com/",
      src: "/images/sponsors/local83.png",
      height: sponsorHeight,
      width: sponsorWidth,
    },
    {
      name: "Free To Be Power Yoga",
      href: "http://www.freetobepoweryoga.com",
      src: "/images/sponsors/ftb.jpg",
      height: sponsorHeight,
      width: sponsorWidth,
    },
    {
      name: "Career Strategy Consultants",
      href: "http://www.careerstrategyinc.com",
      src: "/images/sponsors/csc.png",
      height: sponsorHeight,
      width: sponsorWidth,
    },
    {
      name: "Ohio Valley Waste Systems",
      href: "http://www.garbagefolks.com",
      src: "/images/sponsors/garbagefolks_green.png",
      height: sponsorHeight,
      width: sponsorWidth,
    },
    {
      name: "Presutti, Derosa, & Booth-Barricklow Family Dentistry",
      href: "http://www.stcfamilydentists.com",
      src: "/images/sponsors/stcfamilydentists.png",
      height: sponsorHeight,
      width: sponsorWidth,
    },
    {
      name: "REDLattice",
      href: "https://redlattice.com",
      src: "/images/sponsors/redlattice-black.png",
      height: sponsorHeight,
      width: sponsorWidth,
    },
    {
      name: "Brad Becca Athletics",
      href: "https://bradbecca.com",
      src: "/images/sponsors/bb.jpg",
      height: sponsorHeight,
      width: sponsorWidth,
    },
  ];
  const handleOnClick = (index, image, e) => {
    console.log(index, image, e);
    window.location.href = image.href;
  };

  useEffect(() => {
    const handleWindowSizeChange = () => {
      if (window.innerWidth <= 768) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    };
    setWidth(window.innerWidth);
    if (window.innerWidth <= 768) {
      setMobile(true);
    } else {
      setMobile(false);
    }
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <Container text style={{ marginTop: "5%", marginBottom: "5%" }}>
      <Header style={{ textAlign: "center", marginBottom: "5%" }} as="h1">
        Title Sponsor
      </Header>
      <Container
        style={{
          marginTop: "5%",
          marginBottom: "5%",
        }}
      >
        <Segment
          color="yellow"
          style={{
            boxShadow: "0px 0px 60px 20px #BF9B30",
            margin: "4em",
            padding: "1em",
            backgroundColor: "#000",
          }}
        >
          <a href={titleSponsor.href}>
            <Image
              src={titleSponsor.logo}
              size="massive"
              style={{
                padding: "2.5%",
                width: titleSponsorWidth,
                height: titleSponsorHeight,
              }}
            />
          </a>
        </Segment>
      </Container>
      <br />
      <Header style={{ textAlign: "center", marginBottom: "5%" }} as="h1">
        Proud Sponsors
      </Header>
      <Container
        style={{
          marginTop: "5%",
          marginBottom: "5%",
        }}
      >
        <Gallery
          images={sponsors}
          onClick={handleOnClick}
          thumbnailImageComponent={ImageComponent}
        />
      </Container>
    </Container>
  );
}
