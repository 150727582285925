import { Icon, Label, Segment, Grid, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Particles from "reactparticles.js";
import TheCindyOFoundationLogo from "./logo";

export default function DonateBarDesktop({ openDonationModal }) {
  return (
    <Segment style={{ borderRadius: "0em" }}>
      <Particles
        id="donate-bar-particles-desktop"
        config="/configs/donate-bar-particles.json"
      />
      <Grid columns={5} padded="horizontally" verticalAlign="middle">
        <Grid.Column width={1} />
        <Grid.Column width={4} as={Link} to="/">
          {TheCindyOFoundationLogo}
        </Grid.Column>
        <Grid.Column textAlign="right" width={8} />
        <Grid.Column width={2} textAlign="center">
          <Button as="div" labelPosition="right" onClick={openDonationModal}>
            <Button basic size="large" color="green">
              Donate
            </Button>
            <Label color="green" pointing="left">
              <Icon name="dollar" size="large" />
            </Label>
          </Button>
        </Grid.Column>
        <Grid.Column width={1} />
      </Grid>
    </Segment>
  );
}
