import { useState } from "react";
import {
  Menu,
  Image,
  Segment,
  Grid,
  Icon,
  Container,
  Header,
} from "semantic-ui-react";
import "./index.css";

export default function Staff() {
  const [po, setPO] = useState("info");
  const [mb, setMB] = useState("info");
  const [ce, setCE] = useState("info");
  const [dk, setDK] = useState("info");
  const [cm, setCM] = useState("info");
  const [sm, setSM] = useState("info");
  const [so, setSO] = useState("info");
  const [nk, setNK] = useState("info");

  const staff = [
    {
      id: "po",
      active: po,
      name: "Patrick O'Donnell",
      image: "images/staff/patrick.jpg",
      role: "President",
      email: "podonnell@thecindyofoundation.com",
      bio: `Patrick is the founder/president of the Cindy O’Donnell Foundation. Patrick is a 2010 graduate
    of Shadyside High School and a 2015 graduate of Ohio University who currently works as a Golf Professional at TPC 
    Twin Cities in Minneapolis, Minnesota. Patrick and his wife Sarina welcomed their first child Sebastian in January. 
    Patrick is one of three boys of Cindy. Patrick is a very driven and passionate person and started the COF to keep his mother’s name active in the community.`,
      quote: `“I started this foundation to give back to families that went through similar situations as I did. I lost my
    mother my senior year of high school on Christmas Eve, from that point on my life was flipped upside
    down. My mother was my best friend and a very big influence on my life and future. I leaned on my
    mother for advice, guidance, and friendship. I experienced a lot of hardship from that loss but was very
    fortunate to have a great family and friends to lean on.”
    “I created this foundation to make a difference. I want to help families get through tough times by not
    only giving money but creating a support platform and a family feel. I want families to feel that they are
    a part of the Cindy O’Donnell Foundation family.”`,
      facebook: "https://www.facebook.com/patrick.odonnell.733",
      instagram: "https://www.instagram.com/odonnell_patrick/",
      linkedin: "https://www.linkedin.com/in/patrick-o-donnell-463a77b1/",
    },
    {
      id: "mb",
      active: mb,
      name: "Mason Bigler",
      image: "images/staff/mason.jpeg",
      role: "Vice President",
      email: "mbigler@thecindyofoundation.com",
      bio: `Mason, a 2010 graduate of Shadyside High School and a 2014 graduate of Ohio University, is
    currently a Health Care Administrator at Rolling Hills Rehabilitation and Care Center. Mason is
    passionate about giving back to others so when asked to be a part of the staff it was a no
    brainer for him.`,
      quote: `“When Patrick first approached me about the idea and the mission of the
    Cindy O’Donnell Foundation, I could not sign up quick enough. Cindy was an amazing person
    and I’m honored to be able to serve the Foundation in her honor. I’m excited for the future
    growth of the Foundation and being a part of such a great organization.”`,
      facebook: "https://www.facebook.com/mason.bigler",
      twitter: "https://twitter.com/mason_bigler",
      linkedin: "https://www.linkedin.com/in/mason-bigler-b76a9999/",
    },
    {
      id: "dk",
      active: dk,
      name: "Dylan Kimble",
      image: "images/staff/dylan.png",
      role: "Secretary & Director of Logistics",
      email: "dkimble@thecindyofoundation.com",
      bio: `Dylan is a 2010 graduate of Shadyside High School, and a 2015 graduate of The 
    Ohio State University. He is currently employed as the Human Resources Manager of Career 
    Strategy Consultants Inc., located in his hometown of Shadyside, OH. `,
      quote: `"The opportunity to give back to our community and help families in need all while 
    honoring such a special woman such as Cindy, is once in a lifetime. I couldn't be more 
    excited for the future of this foundation"`,
      facebook: "https://www.facebook.com/dylan.kimble",
      twitter: "https://twitter.com/dyl_kimble",
      instagram: "https://www.instagram.com/dylan_kimble/",
      linkedin: "https://www.linkedin.com/in/dylan-kimble-56bb5289/",
    },
    {
      id: "ce",
      active: ce,
      name: "Colby Emery",
      image: "images/staff/colby.jpg",
      role: "Treasurer & Director of Development",
      email: "cemery@thecindyofoundation.com",
      bio: `Colby is a 2010 graduate of Shadyside High School and a 2014 graduate of Ohio University who
    currently works out of Pittsburgh, PA for Medtronic as a Medical Device Representative. Colby
    lives in Cranberry, PA with his girlfriend Danielle, daughter Gracelyn, and dog Benny. He looks forward to 
    implementing his skills and passion to help grow this foundation with the effort of helping others 
    and changing lives.`,
      quote: `“Cindy was like a second mom to me as I grew up with her being my babysitter since I was two
    years old. She instilled certain values and morals that I use in my everyday life today. Her
    kindness and well-being affected others around her in a tremendously positive way. So, not
    only am I passionate about honoring her memory but I want to pay it forward by giving back to
    others the way she was able to give to me.”`,
      facebook: "https://www.facebook.com/colby.emery.7",
      twitter: "https://twitter.com/Colby_Emery",
      instagram: "https://www.instagram.com/colby_emery/",
      linkedin: "https://www.linkedin.com/in/colby-emery-23bb2595/",
    },
    {
      id: "sm",
      active: sm,
      name: "Sean Martin",
      image: "images/staff/sean.png",
      role: "Director of Volunteers",
      email: "smartin@thecindyofoundation.com",
      bio: `Sean Martin, a 2010 graduate of Williamstown High School and 2015 graduate of Ohio University, 
    currently works for Defense Finance and Accounting Service as an IT Specialist. He is currently pursuing his Masters in IT Cybersecurity at Georgia Tech. 
    Sean’s passions are his faith, spending time with family and friends, as well as donating time to his and other communities`,
      quote: `"Since 2007, I have had a connection with Shadyside after my high school team played Shadyside in football. 
    I will never forget the small-town atmosphere and connection the community had that made me feel like I was back at home in Williamstown. 
    Meeting Patrick my freshman year of winter quarter in 2011, Sargent Hall was all God’s planning. Being one of my best friends, this was 
    an easy choice joining to be able to give back to the awesome community and assisting all my brothers of Shadyside!"`,
      facebook: "https://www.facebook.com/sean8m",
      instagram: "https://www.instagram.com/sean8m",
      linked: "https://www.linkedin.com/in/sean8m",
    },
    {
      id: "cm",
      active: cm,
      name: "Cassidy McCullough",
      image: "images/staff/cassidy.png",
      role: "Director of Operations",
      email: "cassidy@cindyofoundation.org",
      bio: `Cassidy is a 2013 graduate of River High School in Hannibal, OH. She attended West 
    Virginia Wesleyan College on a full track scholarship, in which she was member of their track 
    and field team for 4 years. Cassidy graduated with a Bachelors Degree of Exercise Science in 
    2017. She is currently employed as a Student Specialist for the Family Medicine Residency 
    Program at Wheeling Hospital. `,
      quote: `"I am honored to be a part of such a special organization with a 
    tremendous group of people."`,
      facebook: "https://www.facebook.com/cassidy.mccullough.73",
      instagram: "https://www.instagram.com/cass.mcculloughh/",
      linked: "https://www.linkedin.com/in/cassidy-mccullough-131810157/",
    },
    {
      id: "so",
      active: so,
      name: "Sarina O'Donnell",
      image: "images/staff/sarina.jpg",
      role: "Director of Marketing",
      email: "sarina@cindyofoundation.org",
      bio: `Sarina is a Texas native and LSU graduate. She is the wife of Patrick and mother to their son, Sebastian. She looks forward to using her past experiences working with the New Orleans Saints & Pelicans and Minnesota Timberwolves & Lynx to enhance marketing and social media.`,
      quote: `"My first time meeting Patrick, he spoke of his mother and this foundation with such passion and heart that I wanted to learn more. The values that the COF stands for and supports, fuels my hunger to help anyway I can."`,
      facebook: "https://www.facebook.com/sarina.natera",
      instagram: "https://www.instagram.com/sarina_odonnell/",
    },
    {
      id: "nk",
      active: nk,
      name: "Noah Kritz",
      image: "images/staff/noah.jpg",
      role: "Director of IT",
      email: "nkritz@cindyofoundation.org",
      bio: `Noah, a 2010 graduate of Shadyside HS and 2019 graduate of Ohio State, lives in Shadyside
    with his wife and two kids. He works as a software engineer at REDLattice.`,
      quote: `“Patrick reached out to me about a website for the foundation and I was all in after I heard his heart behind everything. Everything I remember about Cindy is positive and filled with light. A foundation built around shining that light into the community is something that I’m proud to be a part of. Glory to God for everything we accomplish.”`,
      github: "https://github.com/kr1tzy",
    },
  ];

  const staffState = {
    po: setPO,
    mb: setMB,
    ce: setCE,
    dk: setDK,
    cm: setCM,
    sm: setSM,
    so: setSO,
    nk: setNK,
  };

  const handleItemClick = (e, { name }) => {
    e.preventDefault();
    staffState[e.target.id](name);
  };

  return (
    <Container
      text
      textAlign="center"
      style={{ marginTop: "5%", marginBottom: "5%" }}
    >
      <Header as="h1">Staff</Header>
      {staff.map((staffMember, key) => {
        return (
          <Segment
            key={key}
            color="blue"
            style={{ marginTop: "5%", marginBottom: "5%" }}
          >
            <Grid stackable divided>
              <Grid.Row>
                <Grid.Column width={5}>
                  <Image size="large" src={staffMember.image} />
                </Grid.Column>
                <Grid.Column width={11}>
                  <Menu attached="top" tabular>
                    <Menu.Item
                      name="info"
                      id={staffMember.id}
                      active={staffMember.active === "info"}
                      onClick={handleItemClick}
                    />
                    <Menu.Item
                      name="bio"
                      id={staffMember.id}
                      active={staffMember.active === "bio"}
                      onClick={handleItemClick}
                    />
                    <Menu.Item
                      name="quote"
                      id={staffMember.id}
                      active={staffMember.active === "quote"}
                      onClick={handleItemClick}
                    />
                  </Menu>
                  {/* Anonymous function to check the state for the staff member */}
                  {(() => {
                    switch (staffMember.active) {
                      case "info":
                        return (
                          <div className="info">
                            <span className="name">{staffMember.name}</span>
                            <span className="role">{staffMember.role}</span>
                            <div>
                              {/* Each social network is checked before rendering an icon */}
                              {staffMember.facebook ? (
                                <a href={staffMember.facebook}>
                                  <Icon
                                    circular
                                    color="black"
                                    name="facebook f"
                                  />
                                </a>
                              ) : null}
                              {staffMember.instagram ? (
                                <a href={staffMember.instagram}>
                                  <Icon
                                    circular
                                    color="blue"
                                    name="instagram"
                                  />
                                </a>
                              ) : null}
                              {staffMember.twitter ? (
                                <a href={staffMember.twitter}>
                                  <Icon circular inverted name="twitter" />
                                </a>
                              ) : null}
                              {staffMember.github ? (
                                <a href={staffMember.github}>
                                  <Icon
                                    circular
                                    inverted
                                    color="grey"
                                    name="github"
                                  />
                                </a>
                              ) : null}
                              {staffMember.linkedin ? (
                                <a href={staffMember.linkedin}>
                                  <Icon
                                    circular
                                    color="blue"
                                    inverted
                                    name="linkedin"
                                  />
                                </a>
                              ) : null}
                              {staffMember.email ? (
                                <a href={"mailto:" + staffMember.email}>
                                  <Icon circular color="grey" name="mail" />
                                </a>
                              ) : null}
                            </div>
                          </div>
                        );
                      case "bio":
                        return <p className="bio">{staffMember.bio}</p>;
                      case "quote":
                        return <p className="quote">{staffMember.quote}</p>;
                      default:
                        return null;
                    }
                  })()}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        );
      })}
    </Container>
  );
}
