import { useState } from "react";
import { Header, Modal } from "semantic-ui-react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import DonateForm from "./DonateForm";
import keys from "../../../../config/keys";

export default function DonateModal({ open, closeDonationModal }) {
  const [stripePromise, setStripePromise] = useState(() =>
    loadStripe(keys.stripe_PK)
  );
  return (
    <Modal open={open} onClose={closeDonationModal} size="small" basic>
      <Modal.Content>
        <Header
          as="h2"
          style={{ textAlign: "center", color: "#FFF" }}
          content="Donation Form"
        />
        <Elements stripe={stripePromise}>
          <DonateForm closeDonationModal={closeDonationModal} />
        </Elements>
      </Modal.Content>
    </Modal>
  );
}
