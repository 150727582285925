import axios from "axios";
import {
  GET_ERRORS,
  CLEAR_ERRORS,
  GET_EVENTS,
  GET_EVENT,
  GET_UPCOMING_EVENTS,
} from "./types";

// Get list of events
export const getEvents =
  (eventsToFetch = null) =>
  async (dispatch) => {
    dispatch({
      type: CLEAR_ERRORS,
    });

    try {
      // If it exists it will be a list (for home page)
      if (eventsToFetch) {
        const getEvents = eventsToFetch.map(async (event) => {
          let response = await axios.get("/api/events/" + event);
          return response.data.event;
        });

        const data = await Promise.all(getEvents);
        dispatch({
          type: GET_EVENTS,
          payload: { list: data },
        });
      } else {
        // No list passed, them all events
        axios.get("/api/events/all").then((response) => {
          dispatch({
            type: GET_EVENTS,
            payload: response.data,
          });
        });
      }
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response,
      });
    }
  };

// Get event with event number
export const getEvent = (event_number) => (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });

  axios
    .get("/api/events/" + event_number)
    .then((response) => {
      dispatch({
        type: GET_EVENT,
        payload: response.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response,
      })
    );
};

// Get list of upcoming events
export const getUpcomingEvents = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });

  try {
    axios.get("/api/events/upcoming").then((response) => {
      dispatch({
        type: GET_UPCOMING_EVENTS,
        payload: response.data,
      });
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response,
    });
  }
};
