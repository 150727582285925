import { Link } from "react-router-dom";
import Particles from "reactparticles.js";
import { Label, Icon, Segment, Grid, Button } from "semantic-ui-react";
import TheCindyOFoundationLogo from "./logo.mobile";

export default function DonateBarMobile({ openDonationModal }) {
  return (
    <Segment style={{ borderRadius: "0em" }}>
      <Particles
        id="donate-bar-particles-mobile"
        config="/configs/donate-bar-particles.json"
      />
      <Grid columns={2} verticalAlign="middle">
        <Grid.Column width={8} textAlign="center" as={Link} to="/">
          {TheCindyOFoundationLogo}
        </Grid.Column>
        <Grid.Column textAlign="center" width={8}>
          <Button as="div" labelPosition="right" onClick={openDonationModal}>
            <Button basic size="large" color="green">
              Donate
            </Button>
            <Label color="green" pointing="left">
              <Icon name="dollar" size="large" />
            </Label>
          </Button>
        </Grid.Column>
      </Grid>
    </Segment>
  );
}
