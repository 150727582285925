import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getUpcomingEvents } from "../../../redux/actions/eventActions";
import Announcements from "./Announcements";
import CarouselSponsors from "./Carousel_sponsors";
import CarouselMain from "./Carousel_main";
import { StickyContainer } from "react-sticky";
import Sticky from "./Sticky";
import {
  Container,
  Image,
  Item,
  Button,
  Grid,
  Icon,
  Segment,
  Card,
} from "semantic-ui-react";

export default function Home() {
  const errors = useSelector((state) => state.errors);
  const authenticated = useSelector((state) => state.auth.isAuthenticated);
  const events = useSelector((state) => state.events);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mobile, setMobile] = useState(false);
  const [width, setWidth] = useState(0);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
    if (width <= 768) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  useEffect(() => {
    dispatch(getUpcomingEvents());
    setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <StickyContainer>
      <Container style={{ marginBottom: "5%" }}>
        {/*
        <Sticky mobile />
        */}
        <CarouselMain />
        <Container text style={{ marginTop: "10%" }}>
          <h1 style={{ textAlign: "center" }}>Mission Statement</h1>
          <p style={{ textAlign: "center" }}>
            The Cindy O'Donnell Foundation is a 501c3 nonprofit organization
            driven to support families in the Ohio Valley struck with illness
            and tragedy in hope of providing a better future for those affected
            by circumstance.
          </p>
        </Container>
        <Container text style={{ marginTop: "10%" }}>
          <Grid stackable divided>
            <Grid.Column width={5}>
              <Image
                size="large"
                src="/images/home/colon-cancer-awareness.jpg"
              />
            </Grid.Column>
            <Grid.Column width={11}>
              <h3>Our Cause</h3>
              <p>
                The Cindy O’Donnell Foundation fully supports The Colorectal
                Cancer Alliance's mission to end this disease within our
                lifetime. As we grow, we hope to become a local partner of the
                American Cancer Society and support their efforts in advancing
                medical treatment to this disease. Meanwhile, it’s our cause to
                aid families directly affected by sudden sickness and disease by
                contributing funds to their families to alleviate financial
                burdens and pursuit of a better life for the immediate family.
              </p>
            </Grid.Column>
          </Grid>
        </Container>
        <Container text style={{ marginTop: "10%" }}>
          <h1>Upcoming Events</h1>
          <Item.Group divided>
            {events.upcoming.length > 0 ? (
              events.upcoming.map((event, key) => {
                const link = "/events/" + event.eventNumber;
                return (
                  <Item style={{ textAlign: "left" }} key={key}>
                    <Item.Image size="medium" src={event.image} />
                    <Item.Content>
                      <Item.Header>{event.eventType}</Item.Header>
                      <Item.Meta>@ {event.location}</Item.Meta>
                      <Item.Description>
                        {event.description} held on{" "}
                        <span style={{ fontStyle: "italic" }}>
                          {event.eventDate}
                        </span>
                        !
                      </Item.Description>
                      {event.show_sponsor && (
                        <Item.Extra>
                          <i>Sponsored by</i>
                          <a href={event.sponsor.href}>{event.sponsor.name}</a>
                        </Item.Extra>
                      )}
                      <div style={{ marginTop: "2.5%" }}>
                        <Link to={link}>
                          <Button
                            size="mini"
                            color="blue"
                            icon="list alternate outline"
                            content="Details"
                          />
                        </Link>
                      </div>
                    </Item.Content>
                  </Item>
                );
              })
            ) : (
              <i>None scheduled, stay tuned</i>
            )}
          </Item.Group>
          <Container text style={{ marginTop: "10%" }}>
            <h1>Announcements</h1>
            <Announcements />
          </Container>
          <Container text style={{ marginTop: "10%" }}>
            <h1>Sponsors</h1>
            <CarouselSponsors />
          </Container>
        </Container>
        <Container text style={{ marginTop: "10%", marginBottom: "10%" }}>
          <Segment style={{ textAlign: "center" }} raised padded color="blue">
            <p style={{ fontSize: "1.5em" }}>
              <Link to="/register">Click here</Link> to stay in the loop!{" "}
              <Icon name="mail" />
            </p>
          </Segment>
        </Container>
      </Container>
    </StickyContainer>
  );
}
