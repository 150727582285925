import { Item } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { HashLink } from "react-router-hash-link";

export default function Announcements() {
  const announcements = useSelector(
    (state) => state.content.home.announcements
  );
  return (
    <Item.Group>
      {announcements.length > 0 &&
        announcements.map((announcement, key) => {
          return (
            <Item key={key} style={{ marginTop: "10%" }}>
              <Item.Image size="small" src={announcement.image} />
              <Item.Content>
                <Item.Header>{announcement.heading}</Item.Header>
                <Item.Meta>{announcement.meta}</Item.Meta>
                <Item.Extra>
                  <HashLink to={announcement.link}>More info</HashLink>
                </Item.Extra>
              </Item.Content>
            </Item>
          );
        })}
    </Item.Group>
  );
}
