import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import {
  Input,
  Message,
  Divider,
  Container,
  Checkbox,
  Icon,
  Button,
  Form,
  Radio,
} from "semantic-ui-react";
import {
  saveGamingSignup,
  createGamingSignupIntent,
} from "../../../../../../redux/actions/stripeActions";
import { objIsEmpty } from "../../../../../../utils";
import cof_gaming_terms from "../../../../../../assets/files/cof_gaming_terms_2022.pdf";
import "./index.css";

export default function SignUpForm({ closeModal }) {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const events = useSelector((state) => state.events);
  const stripes = useSelector((state) => state.stripe);
  const errors = useSelector((state) => state.errors);
  const [error, setError] = useState(null);
  const [receiptEmail, setReceiptEmail] = useState("");
  const [teamName, setTeamName] = useState("");
  const [teamAgrees, setTeamAgrees] = useState(false);
  const [eventNumber, setEventNumber] = useState(0);
  const [eventDescription, setEventDescription] = useState("");
  const [player1Name, setPlayer1Name] = useState("");
  const [player2Name, setPlayer2Name] = useState("");
  const [player3Name, setPlayer3Name] = useState("");
  const [player4Name, setPlayer4Name] = useState("");
  const [player1Email, setPlayer1Email] = useState("");
  const [player2Email, setPlayer2Email] = useState("");
  const [player3Email, setPlayer3Email] = useState("");
  const [player4Email, setPlayer4Email] = useState("");
  const [player1ActivisionId, setPlayer1ActivisionId] = useState("");
  const [player2ActivisionId, setPlayer2ActivisionId] = useState("");
  const [player3ActivisionId, setPlayer3ActivisionId] = useState("");
  const [player4ActivisionId, setPlayer4ActivisionId] = useState("");
  const [player1Location, setPlayer1Location] = useState("");
  const [player2Location, setPlayer2Location] = useState("");
  const [player3Location, setPlayer3Location] = useState("");
  const [player4Location, setPlayer4Location] = useState("");
  const [sendingRequest, setSendingRequest] = useState(false);
  const [teamContactNumber, setTeamContactNumber] = useState("");
  const players = ["player1", "player2", "player3", "player4"];
  const [success, setSuccess] = useState(false);
  const [amount, setAmount] = useState("50.00");
  const data = {
    amount: amount,
    receiptEmail: receiptEmail,
    player1Name: player1Name,
    player2Name: player2Name,
    player3Name: player3Name,
    player4Name: player4Name,
    player1Email: player1Email,
    player2Email: player2Email,
    player3Email: player3Email,
    player4Email: player4Email,
    player1ActivisionId: player1ActivisionId,
    player2ActivisionId: player2ActivisionId,
    player3ActivisionId: player3ActivisionId,
    player4ActivisionId: player4ActivisionId,
    player1Location: player1Location,
    player2Location: player2Location,
    player3Location: player3Location,
    player4Location: player4Location,
  };
  const dataInput = {
    amount: setAmount,
    teamName: setTeamName,
    teamContactNumber: setTeamContactNumber,
    receiptEmail: setReceiptEmail,
    player1Name: setPlayer1Name,
    player2Name: setPlayer2Name,
    player3Name: setPlayer3Name,
    player4Name: setPlayer4Name,
    player1Email: setPlayer1Email,
    player2Email: setPlayer2Email,
    player3Email: setPlayer3Email,
    player4Email: setPlayer4Email,
    player1ActivisionId: setPlayer1ActivisionId,
    player2ActivisionId: setPlayer2ActivisionId,
    player3ActivisionId: setPlayer3ActivisionId,
    player4ActivisionId: setPlayer4ActivisionId,
    player1Location: setPlayer1Location,
    player2Location: setPlayer2Location,
    player3Location: setPlayer3Location,
    player4Location: setPlayer4Location,
  };

  const cardStyle = {
    style: {
      base: {
        iconColor: "#FFF",
        color: "#FFF",
        fontSize: "14px",
        fontSmoothing: "antialiased",
        "::placeholder": {
          color: "#a8a8a8",
        },
      },
      invalid: {
        color: "#e5424d",
        ":focus": {
          color: "red",
        },
      },
    },
  };

  const handleChange = (e, { name, value }) => {
    e.preventDefault();
    dataInput[name](value);
  };

  const handleTeamAgrees = () => setTeamAgrees(!teamAgrees);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSendingRequest(true);

    if (!stripe || !elements) {
      return;
    }

    if (
      // Generate new intents on change
      objIsEmpty(stripes.savedIntent) ||
      stripes.savedIntent.amount != amount ||
      stripes.savedIntent.receiptEmail != receiptEmail ||
      stripes.savedIntent.player1Name != player1Name ||
      stripes.savedIntent.player1Email != player1Email ||
      stripes.savedIntent.player1ActivisionId != player1ActivisionId ||
      stripes.savedIntent.player1Location != player1Location ||
      stripes.savedIntent.player2Name != player2Name ||
      stripes.savedIntent.player2Email != player2Email ||
      stripes.savedIntent.player2ActivisionId != player2ActivisionId ||
      stripes.savedIntent.player2Location != player2Location ||
      stripes.savedIntent.player3Name != player3Name ||
      stripes.savedIntent.player3Email != player3Email ||
      stripes.savedIntent.player3ActivisionId != player3ActivisionId ||
      stripes.savedIntent.player3Location != player3Location ||
      stripes.savedIntent.player4Name != player4Name ||
      stripes.savedIntent.player4Email != player4Email ||
      stripes.savedIntent.player4ActivisionId != player4ActivisionId ||
      stripes.savedIntent.player4Location != player4Location
    ) {
      dispatch(
        createGamingSignupIntent({
          auth,
          amount,
          teamName,
          teamAgrees,
          teamContactNumber,
          eventNumber,
          eventDescription,
          receiptEmail,
          player1Name,
          player1Email,
          player1ActivisionId,
          player1Location,
          player2Name,
          player2Email,
          player2ActivisionId,
          player2Location,
          player3Name,
          player3Email,
          player3ActivisionId,
          player3Location,
          player4Name,
          player4Email,
          player4ActivisionId,
          player4Location,
        })
      );
    }

    if (error) {
      setError(null);
    }
  };

  useEffect(() => {
    if (auth.isAuthenticated) {
      setReceiptEmail(auth.user.email);
    }

    if (events.event) {
      setEventNumber(events.event.eventNumber);
      setEventDescription(events.event.description);
    }

    async function confirmPayment() {
      const payload = await stripe.confirmCardPayment(stripes.clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });

      if (payload.error) {
        setError(payload.error.message);
      } else {
        dispatch(
          saveGamingSignup({
            auth,
            amount,
            teamName,
            teamAgrees,
            teamContactNumber,
            eventNumber,
            eventDescription,
            receiptEmail,
            player1Name,
            player1Email,
            player1ActivisionId,
            player1Location,
            player2Name,
            player2Email,
            player2ActivisionId,
            player2Location,
            player3Name,
            player3Email,
            player3ActivisionId,
            player3Location,
            player4Name,
            player4Email,
            player4ActivisionId,
            player4Location,
            token: stripes.clientSecret,
          })
        );
        setSuccess(true);
      }

      setSendingRequest(false);
    }

    if (!objIsEmpty(errors)) {
      setSuccess(false);
      setSendingRequest(false);
    }

    // Confirm after we receive the Stripe client's secret
    if (stripes.clientSecret) {
      confirmPayment();
    }
  }, [stripes, errors, error]);

  return (
    <Container fluid>
      <Divider inverted horizontal>
        Team
      </Divider>
      {errors["teamName"] && <Message error content={errors["teamName"]} />}
      {errors["teamContactNumber"] && (
        <Message error content={errors["teamContactNumber"]} />
      )}
      {errors["teamAgrees"] && <Message error content={errors["teamAgrees"]} />}
      <div className="team-sign-up-grouped-element">
        <Input
          name="teamName"
          icon="group"
          iconPosition="left"
          onChange={handleChange}
          placeholder="Team name (ex. Team DZP)"
          value={teamName}
          transparent
          inverted
          fluid
        />
        <Divider inverted />
        <Input
          name={"teamContactNumber"}
          icon="call"
          iconPosition="left"
          onChange={handleChange}
          placeholder="One team member's cell # for important info"
          value={teamContactNumber}
          transparent
          inverted
          fluid
        />
        <Divider inverted />
        <Checkbox
          label={
            <label style={{ color: teamAgrees ? "#ffffff" : "gray" }}>
              Do you agree to the{" "}
              <a href={cof_gaming_terms} download>
                terms & conditions
              </a>
              ?
            </label>
          }
          onChange={handleTeamAgrees}
          style={{
            color: "#ffffff",
          }}
        />
      </div>
      <Divider inverted horizontal>
        Players
      </Divider>
      {players.map((player, key) => {
        const playerNamePC = "Player " + (key + 1).toString() + " Name";
        const playerEmailPC = "Player " + (key + 1).toString() + " Email";
        const playerActivisionIdPC =
          "Player " +
          (key + 1).toString() +
          " Activision ID (ex. JimminyDean#1234567)";
        const playerLocationPC =
          "Player " + (key + 1).toString() + " Location (city & state)";
        const playerName = player + "Name";
        const playerEmail = player + "Email";
        const playerActivisionId = player + "ActivisionId";
        const playerLocation = player + "Location";

        return (
          <div key={key}>
            {errors.auth && <Message error content={errors["auth"]} />}
            {errors[playerName] && (
              <Message error content={errors[playerName]} />
            )}
            {errors[playerEmail] && (
              <Message error content={errors[playerEmail]} />
            )}
            {errors[playerActivisionId] && (
              <Message error content={errors[playerActivisionId]} />
            )}
            {errors[playerLocation] && (
              <Message error content={errors[playerLocation]} />
            )}
            <div className="team-sign-up-grouped-element">
              <Input
                name={playerName}
                icon="add user"
                iconPosition="left"
                onChange={handleChange}
                placeholder={playerNamePC}
                value={data[playerName]}
                transparent
                inverted
                fluid
              />
              <Divider inverted />
              <Input
                name={playerEmail}
                icon="mail"
                iconPosition="left"
                onChange={handleChange}
                placeholder={playerEmailPC}
                value={data[playerEmail]}
                transparent
                inverted
                fluid
              />
              <Divider inverted />
              <Input
                name={playerActivisionId}
                icon="game"
                iconPosition="left"
                onChange={handleChange}
                placeholder={playerActivisionIdPC}
                value={data[playerActivisionId]}
                transparent
                inverted
                fluid
              />
              <Divider inverted />
              <Input
                name={playerLocation}
                icon="map pin"
                iconPosition="left"
                onChange={handleChange}
                placeholder={playerLocationPC}
                value={data[playerLocation]}
                transparent
                inverted
                fluid
              />
            </div>
          </div>
        );
      })}
      <Divider inverted horizontal>
        Payment
      </Divider>
      {errors.amount && <Message error content={errors["amount"]} />}
      {errors.card && <Message error content={errors["card"]} />}
      {errors.receiptEmail && (
        <Message error content={errors["receiptEmail"]} />
      )}
      <div className="team-sign-up-element">
        <Input
          name="amount"
          icon="usd"
          iconPosition="left"
          onChange={handleChange}
          value={amount}
          transparent
          hidden
          disabled
          inverted
          fluid
        />
      </div>
      <div className="team-sign-up-element">
        <CardElement id="card-element" options={cardStyle} />
      </div>
      <div className="team-sign-up-element">
        <Input
          name="receiptEmail"
          icon="mail"
          iconPosition="left"
          onChange={handleChange}
          placeholder="Email to send receipt to"
          value={receiptEmail}
          transparent
          inverted
          fluid
        />
      </div>
      <Divider />
      {errors.server && (
        <Message
          error
          color="orange"
          content={`Contact Admin (${errors.code}) - ${errors.server}`}
        />
      )}
      {Object.keys(errors).length > 0 && (
        <Message error content={"Please fix the error(s) above."} />
      )}
      {success && (
        <Message color="green" content="Success! Thank you for signing up!" />
      )}
      <div style={{ textAlign: "center" }}>
        <Button
          color="green"
          size="tiny"
          loading={sendingRequest}
          disabled={!stripe || !elements || sendingRequest || success}
          onClick={handleSubmit}
          inverted
        >
          <Icon name="checkmark" /> Confirm
        </Button>
        <Button color="red" size="tiny" onClick={closeModal} inverted>
          <Icon name="cancel" /> {success ? "Close" : "Not now"}
        </Button>
      </div>
    </Container>
  );
}
