import axios from "axios";
import { GET_ERRORS, CLEAR_ERRORS, GET_SITE_CONTENT } from "./types";

// Get all site content
export const getSiteContent = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });

  try {
    axios.get("/api/content/all").then((response) => {
      dispatch({
        type: GET_SITE_CONTENT,
        payload: response.data,
      });
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response,
    });
  }
};
