import React, { Component } from "react";
import Slider from "react-slick/lib";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";
import { Image } from "semantic-ui-react";

export default class Carousel extends Component {
  render() {
    const pics = [
      {
        alt: "Raze International",
        src: "/images/sponsors/raze.png",
        href: "http://www.razeinternational.com/",
      },
      {
        alt: "Ohio-West Virginia Excavating",
        src: "/images/sponsors/ohio-wv.jpg",
        href: "http://owvexcavating.com/",
      },
      {
        alt: "Caldwell Dentistry",
        src: "/images/sponsors/caldwell-dentistry.jpg",
        href: "https://www.facebook.com/Caldwell-Dentistry-181338741906417/",
      },
      {
        alt: "Doan Ford",
        src: "/images/sponsors/doanford.png",
        href: "https://www.doanford.com/",
      },
      {
        alt: "Schumacher Law",
        src: "/images/sponsors/lslaw.png",
        href: "http://lschumacherlaw.com",
      },
      {
        alt: "AccessQuip",
        src: "/images/sponsors/accessquip.jpg",
        href: "https://accessquip.com",
      },
      {
        alt: "Wheeling Hospital",
        src: "/images/sponsors/wheelinghospital.jpg",
        href: "https://wheelinghospital.org/",
      },
      {
        alt: "Matt's Tiger Pub",
        src: "/images/sponsors/tigerpub.jpg",
        href: "http://mattstigerpub.com/",
      },
      {
        alt: "Premiums & Promotions",
        src: "/images/sponsors/premiumsandpromotions.jpg",
        href: "https://premiumsandpromotions.com/",
      },
      {
        alt: "DeFelice Bros. Pizza",
        src: "/images/sponsors/defelice.jpg",
        href: "http://eatdefelice.com/",
      },
      {
        alt: "Local 83",
        src: "/images/sponsors/local83.png",
        href: "http://www.ualocal83.com/",
      },
      {
        alt: "Free To Be Power Yoga",
        src: "/images/sponsors/ftb.jpg",
        href: "http://www.freetobepoweryoga.com",
      },
      {
        alt: "Career Strategy Consultants",
        src: "/images/sponsors/csc.png",
        href: "http://www.careerstrategyinc.com",
      },
      {
        alt: "REDLattice",
        src: "/images/sponsors/redlattice-black.png",
        href: "https://redlattice.com",
      },
      {
        alt: "Brad Becca Athletics",
        src: "/images/sponsors/bb.jpg",
        href: "https://bradbecca.com",
      },
    ];

    const settings = {
      centerMode: true,
      dots: false,
      infinite: true,
      speed: 2000,
      autoplaySpeed: 1000,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            centerMode: false,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            centerMode: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            centerMode: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
      ],
    };
    return (
      <Slider {...settings}>
        {pics.map((pic) => (
          <div key="imageDiv">
            <Image {...pic} centered key={pic.key} size="small" />
          </div>
        ))}
      </Slider>
    );
  }
}
