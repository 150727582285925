import { combineReducers } from "redux";
import authReducer from "./authReducer";
import contentReducer from "./contentReducer";
import eventsReducer from "./eventsReducer";
import stripeReducer from "./stripeReducer";
import errorReducer from "./errorReducer";

export default combineReducers({
  auth: authReducer,
  content: contentReducer,
  stripe: stripeReducer,
  events: eventsReducer,
  errors: errorReducer,
});
