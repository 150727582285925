import Slider from "react-slick/lib";
import { Image } from "semantic-ui-react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./style.css";

export default function Carousel() {
  const pics = [
    {
      alt: "patrick-and-cindy",
      src: "/images/family/pat-and-cindy.jpg",
    },
    {
      alt: "brothers",
      src: "/images/family/brothers.jpg",
    },
    {
      alt: "cindy-patrick-prune",
      src: "/images/family/cindy-pat-prune.jpg",
    },
    {
      alt: "patrick-and-cindy-2",
      src: "/images/family/pat-and-cindy-2.png",
    },
    {
      alt: "pat-and-prune",
      src: "/images/family/pat-and-prune.png",
    },
    {
      alt: "george-emery-memorial-pin",
      src: "/images/family/george-emery-memorial-pin.jpg",
    },
  ];

  const settings = {
    centerMode: true,
    dots: true,
    infinite: true,
    speed: 2000,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerMode: false,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      {pics.map((pic) => (
        <div key="imageDiv">
          <Image {...pic} key={pic.key} size="large" />
        </div>
      ))}
    </Slider>
  );
}
