import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import {
  saveDonation,
  createDonationIntent,
  clearStripeState,
} from "../../../../../redux/actions/stripeActions";
import { objIsEmpty } from "../../../../../utils";
import {
  Input,
  Message,
  Divider,
  Container,
  Icon,
  Button,
} from "semantic-ui-react";
import "./index.css";

export default function DonateForm({ closeDonationModal }) {
  const stripe = useStripe();
  const elements = useElements();
  const stripes = useSelector((state) => state.stripe);
  const errors = useSelector((state) => state.errors);
  const auth = useSelector((state) => state.auth);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [sendingRequest, setSendingRequest] = useState(false);
  const [amount, setAmount] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();

  const dataInput = {
    amount: setAmount,
    email: setEmail,
    message: setMessage,
  };

  const handleChange = (e) => {
    e.preventDefault();
    dataInput[e.target.name](e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // to show as loading
    setSendingRequest(true);

    // skip if stripe / elements aren't present
    if (!stripe || !elements) {
      return;
    }

    if (
      // if there are no saved intentions
      // or any of them are different
      // generate new ones
      objIsEmpty(stripes.savedIntent) ||
      stripes.savedIntent.amount != amount ||
      stripes.savedIntent.email != email ||
      stripes.savedIntent.message != message
    ) {
      dispatch(
        createDonationIntent({
          amount,
          email,
          message,
        })
      );
    }

    // reset stripe error
    if (error) {
      setError(null);
    }
  };

  useEffect(() => {
    if (auth.isAuthenticated) {
      setEmail(auth.user.email);
    }

    async function confirmPayment() {
      // complete stripe payment in the client
      const payload = await stripe.confirmCardPayment(stripes.clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });

      // check for stripe errors
      if (payload.error) {
        // display stripe card error
        setError(payload.error.message);
      } else {
        // success! display success message
        // and save the donation in the db
        const token = stripes.clientSecret;
        dispatch(saveDonation({ auth, token, amount, email, message }));
        setSuccess(true);
      }
      // request done
      setSendingRequest(false);
    }

    // Set sending request to false when we get errors
    if (!objIsEmpty(errors)) {
      setSendingRequest(false);
    }

    // Confirm payment after we receive the client secret
    if (stripes.clientSecret) {
      confirmPayment();
    }
  }, [stripes, errors, error]);

  const cardStyle = {
    style: {
      base: {
        iconColor: "#FFF",
        color: "#FFF",
        fontSize: "14px",
        fontSmoothing: "antialiased",
        "::placeholder": {
          color: "#a8a8a8",
        },
      },
      invalid: {
        color: "#e5424d",
        ":focus": {
          color: "red",
        },
      },
    },
  };

  return (
    <Container>
      {success && (
        <Message
          color="green"
          content="Success! Thank you for your donation!"
        />
      )}
      {errors.amount && <Message error content={errors["amount"]} />}
      {errors.email && <Message error content={errors["email"]} />}
      {error && <Message error content={error} />}
      <div className="donation-element">
        <Input
          name="amount"
          icon="usd"
          iconPosition="left"
          onChange={handleChange}
          placeholder="Amount (ex. $20.00)"
          value={amount}
          transparent
          inverted
          fluid
        />
      </div>
      <div className="donation-element">
        <CardElement id="card-element" options={cardStyle} />
      </div>
      <div className="donation-element">
        <Input
          name="email"
          icon="mail"
          iconPosition="left"
          onChange={handleChange}
          placeholder="Email to send receipt to"
          value={email}
          transparent
          inverted
          fluid
        />
      </div>
      <div className="donation-element">
        <Input
          name="message"
          icon="chat"
          iconPosition="left"
          onChange={handleChange}
          placeholder="Leave us a message (optional)"
          value={message}
          transparent
          inverted
          fluid
        />
      </div>
      <Divider />
      <div style={{ textAlign: "center" }}>
        <Button
          color="green"
          size="tiny"
          loading={sendingRequest}
          disabled={!stripe || !elements || sendingRequest || success}
          onClick={handleSubmit}
          inverted
        >
          <Icon name="checkmark" /> Confirm
        </Button>
        <Button color="red" size="tiny" onClick={closeDonationModal} inverted>
          <Icon name="cancel" /> {success ? "Close" : "Not now"}
        </Button>
      </div>
    </Container>
  );
}
