import { useEffect } from "react";
import { Container, Header, Button, Item, Divider } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import { getEvents } from "../../../redux/actions/eventActions.js";
import Event from "./Event";

// Nested switch to route the correct path of events without App.js's knowledge
export default function Events() {
  return (
    <Routes>
      <Route end path="/" element={<AllEvents />} />
      <Route path=":number" element={<Event />} />
    </Routes>
  );
}

function AllEvents() {
  const errors = useSelector((state) => state.errors);
  const events = useSelector((state) => state.events.list);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getEvents());
  }, []);

  return (
    <Container
      textAlign="center"
      text
      style={{ marginTop: "5%", marginBottom: "5%" }}
    >
      <Header as="h1">Events</Header>
      {(Object.entries(events).length === 0 && (
        <p style={{ fontStyle: "italic" }}>No events in database.</p>
      )) || (
        <Item.Group divided>
          {events
            .sort((a, b) => {
              return parseInt(b.eventNumber) - parseInt(a.eventNumber);
            })
            .map((event, key) => {
              const link = "/events/" + event.eventNumber;
              return (
                <Item key={key}>
                  <Item.Image size="medium" src={event.image} />
                  <Item.Content>
                    <Item.Header>{event.eventType}</Item.Header>
                    <Item.Meta>@ {event.location}</Item.Meta>
                    <Item.Description>
                      {event.description} held on{" "}
                      <span style={{ fontStyle: "italic" }}>
                        {event.eventDate}
                      </span>
                      !
                    </Item.Description>
                    {event.show_sponsor && (
                      <Item.Extra>
                        <i>Sponsored by</i>
                        <a href={event.sponsor.href}>{event.sponsor.name}</a>
                      </Item.Extra>
                    )}
                    <Divider hidden />
                    <div style={{ marginTop: "2.5%" }}>
                      <Link to={link}>
                        <Button
                          size="medium"
                          color="blue"
                          icon="list alternate outline"
                          content="Details"
                        />
                      </Link>
                    </div>
                  </Item.Content>
                </Item>
              );
            })}
        </Item.Group>
      )}
    </Container>
  );
}
