import React, { Component } from "react";
import Slider from "react-slick/lib";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";
import { Image } from "semantic-ui-react";

export default class Carousel extends Component {
  render() {
    const pics = [
      {
        alt: "logo",
        src: "/images/logo.png",
      },
      {
        alt: "gaming_logo",
        src: "/images/cof_gaming_logo.png",
      },
      {
        alt: "scholarship",
        src: "/images/home/carousel/scholarship.jpg",
      },
      {
        alt: "family-of-the-year",
        src: "/images/home/carousel/family-of-the-year.jpg",
      },
      {
        alt: "cindy",
        src: "/images/home/carousel/cindy.png",
      },
      {
        alt: "staff",
        src: "/images/home/carousel/staff.jpg",
      },
    ];

    const settings = {
      centerMode: true,
      dots: true,
      infinite: true,
      speed: 2000,
      autoplaySpeed: 3000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            centerMode: false,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            centerMode: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 480,
          settings: {
            centerMode: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
      ],
    };
    return (
      <Slider {...settings}>
        {pics.map((pic) => (
          <div key="imageDiv">
            <Image {...pic} key={pic.key} size="large" />
          </div>
        ))}
      </Slider>
    );
  }
}
