import { GET_EVENTS, GET_EVENT, GET_UPCOMING_EVENTS } from "../actions/types";

const initialState = {
  list: [],
  upcoming: [],
  event: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_EVENTS:
      return {
        ...state,
        list: action.payload.list,
      };
    case GET_UPCOMING_EVENTS:
      return {
        ...state,
        upcoming: action.payload.list,
      };
    case GET_EVENT:
      return {
        ...state,
        event: action.payload.event,
      };
    default:
      return state;
  }
}
