import { useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Segment, Menu } from "semantic-ui-react";

export default function NavBarDesktop({ links }) {
  const [activeItem, setActiveItem] = useState("");
  const handleItemClick = (e, { name }) => setActiveItem(name);
  const onMouseOver = (e) => (e.target.style.backgroundColor = "#F0F0F0");
  const onMouseOut = (e) => (e.target.style.backgroundColor = "#FFF");

  return (
    <Segment
      inverted
      color="blue"
      style={{ textAlign: "center", borderRadius: "0em" }}
    >
      <Menu compact inverted color="blue">
        <Menu.Menu>
          {links.map((link) =>
            link.href ? (
              <Menu.Item
                key={link.key}
                as={Link}
                to={link.href}
                content={link.content}
                style={{ fontSize: "1.2em" }}
                active={activeItem === link.key}
                onClick={handleItemClick}
              />
            ) : (
              <Dropdown
                item
                text={link.content}
                key={link.key}
                style={{ fontSize: "1.2em", zIndex: 999 }}
              >
                <Dropdown.Menu style={{ backgroundColor: "#FFF" }}>
                  {link.children.map((c, key) => (
                    <Link key={key} to={c.href}>
                      <Dropdown.Item
                        onMouseOver={onMouseOver}
                        onMouseOut={onMouseOut}
                        style={{ color: "#000" }}
                      >
                        {c.content}
                      </Dropdown.Item>
                    </Link>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )
          )}
        </Menu.Menu>
      </Menu>
    </Segment>
  );
}
