import { useState } from "react";
import { Header, Modal } from "semantic-ui-react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import SignUpForm from "./SignUpForm";
import keys from "../../../../../config/keys";

export default function SteakFryModal({ modalOpen, closeModal }) {
  const [stripePromise, setStripePromise] = useState(() =>
    loadStripe(keys.stripe_PK)
  );
  return (
    <Modal
      open={modalOpen}
      onClose={closeModal}
      closeOnDimmerClick={false}
      size="small"
      basic
    >
      <Modal.Content>
        <Header
          style={{ textAlign: "center", color: "#FFF" }}
          as="h2"
          content="Steak fry signup"
        />
        <p style={{ color: "#FFF", textAlign: "center", marginBottom: "5%" }}>
          Enter each attendee's name, allergies, and age followed by your
          payment.
        </p>
        <Elements stripe={stripePromise}>
          <SignUpForm closeModal={closeModal} />
        </Elements>
      </Modal.Content>
    </Modal>
  );
}
