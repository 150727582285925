import React, { Component } from "react";
import { Container, Header, Item, Image, List, Grid } from "semantic-ui-react";

export default function COF() {
  return (
    <Container
      text
      textAlign="center"
      style={{ marginTop: "5%", marginBottom: "5%" }}
    >
      <Header as="h1">About Us</Header>
      <Image centered size="large" src="/images/logo.png" />
      <Item>
        <Item.Description>
          The Cindy O’Donnell Foundation is a local Non-Profit Organization that
          gives back to the community of Shadyside and the Ohio Valley. Our
          mission includes presenting a scholarship in the name of Cindy
          O’Donnell to a high school student wanting to earn an education at a
          college or university. Additionally, it is our vision to give back to
          the community of Shadyside Athletics and American Cancer Society
          through future partnerships.
        </Item.Description>
      </Item>
      <Item.Group>
        <Grid stackable style={{ marginTop: "5%", marginBottom: "5%" }}>
          <Grid.Row>
            <Grid.Column width={8}>
              <Item>
                <Item.Content>
                  <Item.Description>
                    <List style={{ marginTop: "5%" }}>
                      <List.Header
                        style={{
                          fontSize: "1.3em",
                          marginBottom: "2.5%",
                        }}
                      >
                        Advisory Directors
                      </List.Header>
                      <List.Item>Evan Holmes</List.Item>
                      <List.Item>Janae Holmes</List.Item>
                      <List.Item>Ryan Holmes</List.Item>
                      <List.Item>Sherry Paprocki</List.Item>
                      <List.Item>Ray Paprocki</List.Item>
                      <List.Item>Cynthia Caldwell</List.Item>
                      <List.Item>Prune O'Donnell</List.Item>
                      <List.Item>Mandi O'Donnell</List.Item>
                      <List.Item>Sarina O'Donnell</List.Item>
                      <List.Item>Tyler Cline</List.Item>
                      <List.Item>Eric O'Donnell</List.Item>
                    </List>
                  </Item.Description>
                </Item.Content>
              </Item>
            </Grid.Column>
            <Grid.Column width={8}>
              <Item>
                <Item.Content>
                  <Item.Description>
                    <List style={{ marginTop: "5%" }}>
                      <List.Header
                        style={{
                          fontSize: "1.3em",
                          marginBottom: "2.5%",
                        }}
                      >
                        Board of Directors
                      </List.Header>
                      <List.Item>Patrick O'Donnell</List.Item>
                      <List.Item>Mason Bigler</List.Item>
                      <List.Item>Dylan Kimble</List.Item>
                      <List.Item>Colby Emery</List.Item>
                      <List.Item>Noah Kritz</List.Item>
                    </List>
                  </Item.Description>
                </Item.Content>
              </Item>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Item.Group>
    </Container>
  );
}
