import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import {
  Input,
  Message,
  Divider,
  Container,
  Icon,
  Button,
  Form,
  Radio,
} from "semantic-ui-react";
import {
  saveGolfSignup,
  createGolfSignupIntent,
} from "../../../../../../redux/actions/stripeActions";
import { objIsEmpty } from "../../../../../../utils";
import "./index.css";

export default function SignUpForm({ closeModal }) {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const events = useSelector((state) => state.events);
  const stripes = useSelector((state) => state.stripe);
  const errors = useSelector((state) => state.errors);
  const auth = useSelector((state) => state.auth);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [eventNumber, setEventNumber] = useState(0);
  const [eventDescription, setEventDescription] = useState("");
  const [sendingRequest, setSendingRequest] = useState(false);
  const [receiptEmail, setReceiptEmail] = useState("");
  const [player1Name, setPlayer1Name] = useState("");
  const [player2Name, setPlayer2Name] = useState("");
  const [player3Name, setPlayer3Name] = useState("");
  const [player4Name, setPlayer4Name] = useState("");
  const [player1Email, setPlayer1Email] = useState("");
  const [player2Email, setPlayer2Email] = useState("");
  const [player3Email, setPlayer3Email] = useState("");
  const [player4Email, setPlayer4Email] = useState("");
  const [player1ShirtSize, setPlayer1ShirtSize] = useState("");
  const [player2ShirtSize, setPlayer2ShirtSize] = useState("");
  const [player3ShirtSize, setPlayer3ShirtSize] = useState("");
  const [player4ShirtSize, setPlayer4ShirtSize] = useState("");
  const players = ["player1", "player2", "player3", "player4"];
  const [amount, setAmount] = useState("400.00");
  const data = {
    amount: amount,
    receiptEmail: receiptEmail,
    player1Name: player1Name,
    player2Name: player2Name,
    player3Name: player3Name,
    player4Name: player4Name,
    player1Email: player1Email,
    player2Email: player2Email,
    player3Email: player3Email,
    player4Email: player4Email,
    player1ShirtSize: player1ShirtSize,
    player2ShirtSize: player2ShirtSize,
    player3ShirtSize: player3ShirtSize,
    player4ShirtSize: player4ShirtSize,
  };
  const dataInput = {
    amount: setAmount,
    receiptEmail: setReceiptEmail,
    player1Name: setPlayer1Name,
    player2Name: setPlayer2Name,
    player3Name: setPlayer3Name,
    player4Name: setPlayer4Name,
    player1Email: setPlayer1Email,
    player2Email: setPlayer2Email,
    player3Email: setPlayer3Email,
    player4Email: setPlayer4Email,
    player1ShirtSize: setPlayer1ShirtSize,
    player2ShirtSize: setPlayer2ShirtSize,
    player3ShirtSize: setPlayer3ShirtSize,
    player4ShirtSize: setPlayer4ShirtSize,
  };

  const cardStyle = {
    style: {
      base: {
        iconColor: "#FFF",
        color: "#FFF",
        fontSize: "14px",
        fontSmoothing: "antialiased",
        "::placeholder": {
          color: "#a8a8a8",
        },
      },
      invalid: {
        color: "#e5424d",
        ":focus": {
          color: "red",
        },
      },
    },
  };

  const handleChange = (e, { name, value }) => {
    e.preventDefault();
    dataInput[name](value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSendingRequest(true);

    if (!stripe || !elements) {
      return;
    }

    if (
      // Generate new intents on change
      objIsEmpty(stripes.savedIntent) ||
      stripes.savedIntent.amount != amount ||
      stripes.savedIntent.receiptEmail != receiptEmail ||
      stripes.savedIntent.player1Name != player1Name ||
      stripes.savedIntent.player1Email != player1Email ||
      stripes.savedIntent.player1ShirtSize != player1ShirtSize ||
      stripes.savedIntent.player2Name != player2Name ||
      stripes.savedIntent.player2Email != player2Email ||
      stripes.savedIntent.player2ShirtSize != player2ShirtSize ||
      stripes.savedIntent.player3Name != player3Name ||
      stripes.savedIntent.player3Email != player3Email ||
      stripes.savedIntent.player3ShirtSize != player3ShirtSize ||
      stripes.savedIntent.player4Name != player4Name ||
      stripes.savedIntent.player4Email != player4Email ||
      stripes.savedIntent.player4ShirtSize != player4ShirtSize
    ) {
      dispatch(
        createGolfSignupIntent({
          auth,
          amount,
          eventNumber,
          eventDescription,
          receiptEmail,
          player1Name,
          player1Email,
          player1ShirtSize,
          player2Name,
          player2Email,
          player2ShirtSize,
          player3Name,
          player3Email,
          player3ShirtSize,
          player4Name,
          player4Email,
          player4ShirtSize,
        })
      );
    }

    if (error) {
      setError(null);
    }
  };

  useEffect(() => {
    if (auth.isAuthenticated) {
      setReceiptEmail(auth.user.email);
    }

    if (events.event) {
      setEventNumber(events.event.eventNumber);
      setEventDescription(events.event.description);
    }

    async function confirmPayment() {
      const payload = await stripe.confirmCardPayment(stripes.clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });

      if (payload.error) {
        setError(payload.error.message);
      } else {
        dispatch(
          saveGolfSignup({
            auth,
            amount,
            eventNumber,
            eventDescription,
            receiptEmail,
            player1Name,
            player1Email,
            player1ShirtSize,
            player2Name,
            player2Email,
            player2ShirtSize,
            player3Name,
            player3Email,
            player3ShirtSize,
            player4Name,
            player4Email,
            player4ShirtSize,
            token: stripes.clientSecret,
          })
        );
        setSuccess(true);
      }

      setSendingRequest(false);
    }

    if (!objIsEmpty(errors)) {
      setSuccess(false);
      setSendingRequest(false);
    }

    // Confirm after we receive the Stripe client's secret
    if (stripes.clientSecret) {
      confirmPayment();
    }
  }, [stripes, errors, error]);

  return (
    <Container fluid>
      <Divider inverted horizontal>
        Players
      </Divider>
      {players.map((player, key) => {
        const playerNamePC = "Player " + (key + 1).toString() + " Name";
        const playerEmailPC = "Player " + (key + 1).toString() + " Email";
        const playerName = player + "Name";
        const playerEmail = player + "Email";
        const playerShirtSize = player + "ShirtSize";

        return (
          <div key={key}>
            {errors.auth && <Message error content={errors["auth"]} />}
            {errors[playerName] && (
              <Message error content={errors[playerName]} />
            )}
            {errors[playerEmail] && (
              <Message error content={errors[playerEmail]} />
            )}
            {errors[playerShirtSize] && (
              <Message error content={errors[playerShirtSize]} />
            )}
            <div className="team-sign-up-grouped-element">
              <Input
                name={playerName}
                icon="add user"
                iconPosition="left"
                onChange={handleChange}
                placeholder={playerNamePC}
                value={data[playerName]}
                transparent
                inverted
              />
              <Divider inverted />
              <Input
                name={playerEmail}
                icon="mail"
                iconPosition="left"
                onChange={handleChange}
                placeholder={playerEmailPC}
                value={data[playerEmail]}
                transparent
                inverted
                fluid
              />
              <Divider inverted />
              <Form inverted>
                <label style={{ color: "#a8a8a8" }}>Shirt Size:</label>
                <Form.Group
                  inline
                  style={{
                    marginTop: "2%",
                    marginBottom: "0px",
                  }}
                >
                  <Form.Field
                    name={playerShirtSize}
                    control={Radio}
                    label="S"
                    value="S"
                    checked={data[playerShirtSize] === "S"}
                    onChange={handleChange}
                  />
                  <Form.Field
                    name={playerShirtSize}
                    control={Radio}
                    label="M"
                    value="M"
                    checked={data[playerShirtSize] === "M"}
                    onChange={handleChange}
                  />
                  <Form.Field
                    name={playerShirtSize}
                    control={Radio}
                    label="L"
                    value="L"
                    checked={data[playerShirtSize] === "L"}
                    onChange={handleChange}
                  />
                  <Form.Field
                    name={playerShirtSize}
                    control={Radio}
                    label="XL"
                    value="XL"
                    checked={data[playerShirtSize] === "XL"}
                    onChange={handleChange}
                  />
                  <Form.Field
                    name={playerShirtSize}
                    control={Radio}
                    label="XXL"
                    value="XXL"
                    checked={data[playerShirtSize] === "XXL"}
                    onChange={handleChange}
                  />
                  <Form.Field
                    name={playerShirtSize}
                    control={Radio}
                    label="XXXL"
                    value="XXXL"
                    checked={data[playerShirtSize] === "XXXL"}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Form>
            </div>
          </div>
        );
      })}
      <Divider inverted horizontal>
        Payment
      </Divider>
      {errors.amount && <Message error content={errors["amount"]} />}
      {errors.card && <Message error content={errors["card"]} />}
      {errors.receiptEmail && (
        <Message error content={errors["receiptEmail"]} />
      )}
      <div
        className="mulligans"
        style={{ textAlign: "center", color: "#69b13b" }}
      >
        <h3>Mulligans included in cost.</h3>
      </div>

      {error && <Message error content={error} />}
      <div className="team-sign-up-element">
        <Input
          name="amount"
          icon="usd"
          iconPosition="left"
          value={amount}
          transparent
          hidden
          disabled
          inverted
          fluid
        />
      </div>
      <div className="team-sign-up-element">
        <CardElement id="card-element" options={cardStyle} />
      </div>
      <div className="team-sign-up-element">
        <Input
          name="receiptEmail"
          icon="mail"
          iconPosition="left"
          onChange={handleChange}
          placeholder="Email to send receipt to"
          value={receiptEmail}
          transparent
          inverted
          fluid
        />
      </div>
      <Divider />
      {errors.server && (
        <Message
          error
          color="orange"
          content={`Contact Admin (${errors.code}) - ${errors.server}`}
        />
      )}
      {(Object.keys(errors).length > 0 || error) && (
        <Message error content={"Please fix the error(s) above."} />
      )}
      {success && (
        <Message color="green" content="Success! Thank you for signing up!" />
      )}
      <div style={{ textAlign: "center" }}>
        <Button
          color="green"
          size="tiny"
          loading={sendingRequest}
          disabled={!stripe || !elements || sendingRequest || success}
          onClick={handleSubmit}
          inverted
        >
          <Icon name="checkmark" /> Confirm
        </Button>
        <Button color="red" size="tiny" onClick={closeModal} inverted>
          <Icon name="cancel" /> {success ? "Close" : "Not now"}
        </Button>
      </div>
    </Container>
  );
}
