import { Container, Header, Icon, Button, Divider } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const navigate = useNavigate();
  return (
    <Container
      text
      textAlign="center"
      style={{ marginTop: "5%", marginBottom: "2.5%" }}
    >
      <Header as="h1" icon>
        <Icon circular color="yellow" name="exclamation triangle" />
        <Divider />
        <Header.Subheader>
          <b>404</b> - Page Not Found
        </Header.Subheader>
        <Divider />
        <Button color="blue" size="medium" onClick={() => navigate("/")}>
          Go home
        </Button>
      </Header>
    </Container>
  );
}
