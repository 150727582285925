import React, { Component } from "react";
import { Container, Item, Header } from "semantic-ui-react";
import Carousel from "./Carousel";

export default function Family() {
  return (
    <Container>
      <Container
        text
        textAlign="center"
        style={{ marginTop: "5%", marginBottom: "5%" }}
      >
        <Header as="h1">COF Family</Header>
        <Carousel />
      </Container>
      <Container
        text
        textAlign="left"
        style={{ marginTop: "5%", marginBottom: "5%" }}
      >
        <Item.Group>
          <Item>
            <Item.Image spaced size="small" src="images/family/cindy.png" />
            <Item.Content>
              <Item.Header style={{ fontSize: "2em" }}>
                <i>Cynthia O'Donnell</i>
              </Item.Header>
              <Item.Meta>August 2, 1955 - December 24, 2009</Item.Meta>
              <Item.Description>
                <p>
                  CYNTHIA A. “CINDY” PAPROCKI O’DONNELL 54 of Shadyside, Ohio
                  died Thursday December 24, 2009 at the Belmont Community
                  Hospital in Bellaire, Ohio.
                </p>
                <p>
                  She was born August 2, 1955 in Columbus, Ohio the daughter of
                  Raymond and Shirley Ann Butler Paprocki.
                </p>
                <p>
                  Cindy was a hair stylist and child care provider. She was a
                  devoted mother, daughter, sister and aunt. She was active in
                  the Shadyside Basketball Moms and a member of the St. Mary
                  Catholic Church in Shadyside.
                </p>
                <p>
                  Surviving are her three sons, Evan Holmes of Chicago,
                  Illinois, Ryan Holmes of Shadyside, Ohio and Patrick O’Donnell
                  of Shadyside, Ohio, her father and mother, Raymond and Shirley
                  Ann Butler Paprocki of Columbus, Ohio, her brother, Ray Sherry
                  Paprocki of Granville, Ohio and her nephew, Justin Paprocki of
                  Hilton Head Island, SC and her niece, Anastasia Paprocki of
                  Boston, Mass.
                </p>
                <a href="http://main.acsevents.org/site/TR?pg=fund&fr_id=9910&pxfid=601205">
                  <p>The American Cancer Society Memorial Page</p>
                </a>
              </Item.Description>
            </Item.Content>
          </Item>
          <br />
          <Item>
            <Item.Image size="small" src="images/family/george.jpg" />
            <Item.Content>
              <Item.Header style={{ fontSize: "2em" }}>
                <i>George Edward Emery</i>
              </Item.Header>
              <Item.Meta>August 15, 1955 - June 25, 2019</Item.Meta>
              <Item.Description>
                <p>
                  George Edward Emery 63, of Shadyside,OH died June 25, 2019 at
                  his home.
                </p>
                <p>
                  George was born August 15, 1955 in Bellaire, OH a son of the
                  late George R. Emery and Katherine Boyd Emery Nemec.
                </p>
                <p>
                  George was retired from the former Ormet Corporation and the
                  East Ohio Regional Transit Authority. He was a 1973 graduate
                  of Shadyside High School and a Methodist by Faith.
                </p>
                <p>Preceding him in death was a sister Jodee Staskey.</p>
                <p>
                  Surviving are two sons, George R. “Bobby” Emery of Shadyside,
                  OH and Colby E. Emery of Shadyside, PA, his companion, Regina
                  Jackson of Triadelphia, WV, two sisters; Jana (Don) Koonce of
                  Dayton, OH and Penny (Terry) Kennedy of Panama City Beach, FL
                  a granddaughter, Madalyn P. Emery step-father, Joseph R. Nemec
                  of Shadyside, OH and several nieces and nephews.
                </p>
                <a href="https://www.tmcfunding.com/search/campaign/3555?layout=single">
                  <p>Celebrating Life's Treasured Memories Page</p>
                </a>
              </Item.Description>
            </Item.Content>
          </Item>
          <br />
          <Item>
            <Item.Image size="small" src="images/family/raymond.jpg" />
            <Item.Content>
              <Item.Header style={{ fontSize: "2em" }}>
                <i>Raymond Eugene Paprocki</i>
              </Item.Header>
              <Item.Meta>July 4, 1930 - March 8, 2021</Item.Meta>
              <Item.Description>
                <p>
                  Raymond Eugene Paprocki, age 90, passed away Monday, March 8,
                  2021 at Mt. Carmel East Hospital.
                </p>
                <p>
                  He was born on July 4, 1930 in Steubenville, OH to the late
                  Stanley and Eleanore Paprocki
                </p>
                <p>
                  Ray served in the U.S. Air Force for a brief time. He was a
                  retired mechanic from TWA with 41 years of service, and served
                  as a union steward at one time. Ray was a former member of St.
                  Matthias Catholic Church and the Knights of Columbus.
                </p>
                <p>
                  In addition to his parents, Ray was preceded in death by his
                  daughter Cindy O'Donnell, siblings Stanley, Teresa and Barney
                  Paprocki, Alma Zilken, Lucy Muenze, brother-in-law Andy Snyder
                </p>
                <p>
                  He is survived by his beloved wife of 66 years, Shirley
                  (Butler) Paprocki; son, Ray (Sherry Beck) Paprocki; brother,
                  Cas (Cathy) Paprocki; sister, Pauline Snyder; five
                  grandchildren, Justin Paprocki (Sarah Demaree), Evan (Janae)
                  Holmes, Ana (Teddy) Piper, Ryan Holmes (Haygen Mowder),
                  Patrick (Sarina) O'Donnell; seven great-grandchildren,
                  Benjamin and Elizabeth Demaree Paprocki, Alexander and
                  Caroline Holmes, Griffin Piper, Rory Ann Holmes, Sebastian
                  O'Donnell.
                </p>
                <a href="https://www.legacy.com/amp/obituaries/dispatch/198002867">
                  <p>Memorial Page</p>
                </a>
              </Item.Description>
            </Item.Content>
          </Item>
          <br />
          <Item>
            <Item.Image size="small" src="images/family/shirley-paprocki.jpg" />
            <Item.Content>
              <Item.Header style={{ fontSize: "2em" }}>
                <i>Shirley Paprocki</i>
              </Item.Header>
              <Item.Meta>June 20, 1935 - April 24, 2023</Item.Meta>
              <Item.Description>
                <p>
                  Shirley (Butler) Paprocki died April 24, 2023, at peace after
                  a monthslong series of healthcare issues while residing at
                  Smith’s Mill Health Campus in New Albany.
                </p>
                She was born June 20, 1935, in Rochester, New York, to Andrew
                and Elizabeth “Betty” (Broderick) Butler. Her sister, Mary Lou
                (Chichka), arrived a few years later. The family moved several
                times before settling in Columbus, Ohio, in time for Shirley to
                graduate from North High School. She soon met Raymond Eugene
                Paprocki of Steubenville, Ohio, and they married in 1955.{" "}
                <p>
                  After a handful of years living outside of Ohio, the couple
                  returned to Columbus for the rest of their lives, where they
                  started a family: a daughter, Cindy (Paprocki) O’Donnell and
                  son, Ray. Shirley and Raymond were married for 66 years before
                  his passing in March 2021. Their daughter preceded them in
                  2009.
                </p>
                <p>
                  Shirley devoted her life to her husband and children, as well
                  as to her friends and family that eventually included five
                  grandchildren and nine great-children (with another due in the
                  fall). She was a dedicated grandmother. She and her sister
                  remained remarkably close until Shirley’s passing; it was a
                  rare day that they did not talk to each other. Shirley also
                  was an exceptionally good neighbor.
                </p>
                <p>
                  In her earlier years, she won several trophies as a bowler.
                  And, fueled by her husband and son’s interest in sports, she
                  enjoyed watching televised athletic events, particularly Ohio
                  State men’s basketball. She and her husband, an airline
                  mechanic for TWA for 40 years, took advantage of an airline
                  benefit to travel together domestically and abroad to
                  locations such as Rome and London. She was a devout Catholic,
                  formerly attending St. Matthias and St. Anthony parishes.
                </p>
                <a href="https://www.dignitymemorial.com/obituaries/gahanna-oh/shirley-paprocki-11264063">
                  <p>Memorial Page</p>
                </a>
              </Item.Description>
            </Item.Content>
          </Item>
        </Item.Group>
      </Container>
    </Container>
  );
}
