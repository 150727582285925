import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { registerUser } from "../../../redux/actions/authActions";
import {
  Container,
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
} from "semantic-ui-react";
import classnames from "classnames";

export default function Register() {
  const errors = useSelector((state) => state.errors);
  const authenticated = useSelector((state) => state.auth.isAuthenticated);
  const newRegister = useSelector((state) => state.auth.newRegister);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const dataInput = {
    first: setFirst,
    last: setLast,
    username: setUsername,
    email: setEmail,
    password: setPassword,
    password2: setPassword2,
  };
  const handleChange = (e) => {
    e.preventDefault();
    dataInput[e.target.name](e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      registerUser({ first, last, username, email, password, password2 })
    );
  };

  useEffect(() => {
    authenticated && navigate("/dashboard");
    newRegister && navigate("/login");
  }, [authenticated, newRegister]);

  return (
    <Container text style={{ marginTop: "5%", marginBottom: "5% " }}>
      <Grid textAlign="center" style={{ height: "90%" }} verticalAlign="middle">
        <Grid.Column style={{ maxHeight: 900, maxWidth: 650 }}>
          {errors.first && <Message error content={errors["first"]} />}
          {errors.last && <Message error content={errors["last"]} />}
          {errors.username && <Message error content={errors["username"]} />}
          {errors.email && <Message error content={errors["email"]} />}
          {errors.password && <Message error content={errors["password"]} />}
          {errors.password2 && <Message error content={errors["password2"]} />}
          <Header as="h2" color="blue" textAlign="center">
            Register with us
          </Header>
          <Form size="large" onSubmit={handleSubmit}>
            <Segment stacked>
              <Form.Group widths="equal">
                <Form.Input
                  fluid
                  icon="user"
                  iconPosition="left"
                  placeholder="First name"
                  name="first"
                  value={first}
                  onChange={handleChange}
                  className={classnames("", {
                    error: errors.first,
                  })}
                />
                <Form.Input
                  fluid
                  icon="user"
                  iconPosition="left"
                  placeholder="Last name"
                  name="last"
                  value={last}
                  onChange={handleChange}
                  className={classnames("", {
                    error: errors.last,
                  })}
                />
              </Form.Group>
              <Form.Input
                fluid
                icon="user circle"
                iconPosition="left"
                placeholder="Username"
                name="username"
                value={username}
                onChange={handleChange}
                className={classnames("", {
                  error: errors.username,
                })}
              />
              <Form.Input
                fluid
                icon="mail"
                iconPosition="left"
                placeholder="Email address (use a Gravatar email for a profile pic)"
                name="email"
                value={email}
                onChange={handleChange}
                className={classnames("", {
                  error: errors.email,
                })}
              />
              <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="Password"
                type="password"
                name="password"
                value={password}
                onChange={handleChange}
                className={classnames("", {
                  error: errors.password,
                })}
              />
              <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="Re-enter password"
                type="password"
                name="password2"
                value={password2}
                onChange={handleChange}
                className={classnames("", {
                  error: errors.password2,
                })}
              />
              <Button color="blue" fluid size="large">
                Sign Up
              </Button>
            </Segment>
          </Form>
          <Message>
            Already have an account? <Link to="/login">Log in</Link>
          </Message>
        </Grid.Column>
      </Grid>
    </Container>
  );
}
