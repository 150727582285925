import { Container, Item, List, Grid, Divider } from "semantic-ui-react";
import scholarship from "../../../assets/files/scholarship-apps/tCOF-Application-5.pdf";

export default function Scholarship() {
  const scholarshipWinners = [
    // TODO Admin page
    {
      childKey: "jacob_and_colton",
      image: "/images/scholarship_winners/jacob_and_colton.jpg",
      header: "2023, Jacob Brown & Colton Wetzel",
      meta: "Otterbein University, Education/Business & Marietta College, Pre-med",
      description:
        "For the second year we're proud to gift two students with funds under the Cindy O'Donnell Foundation Scholarship. Each year becomes more challenging to choose among the candidates due to the talent and personalities we're presented with, and this year was no exception. We're honored to present Jacob Brown & Colton Wetzel with scholarship awards! Congratulations to these two young men, we wish them the very best in everything they pursue.",
    },
    {
      childKey: "emily_and_ann",
      image: "/images/scholarship_winners/emily_and_ann.jpg",
      header: "2022, Emily Davis & Ann Lemly",
      meta: "Marietta College, Neuroscience & West Liberty University, Psychology",
      description:
        "This year we're proud to gift two students with funds under the Cindy O'Donnell Foundation Scholarship! Our first choice receives $5,000 and the runner up $1,000. Each year becomes more challenging to choose among the candidates due to the talent and personalities we're presented with, and this year was no exception. We're honored to present Emily Davis & Ann Lemly with our first joint scholarship award! Congratulations to these two young ladys, we wish them the very best in everything they pursue.",
    },
    {
      childKey: "abigail",
      image: "/images/scholarship_winners/abigail_in_front_of_shs.jpg",
      header: "2021, Abigail Brown",
      meta: "Ohio University, Communication Sciences & Disorders",
      description:
        '"To me winning the Cindy O’Donnell Foundation Scholarship means progress, challenge, and encouragement. The scholarship symbolizes all the progress I have made so far and all of the progress I am yet to make. The scholarship also reminds me of the challenges I have faced and overcame in order to become the person that I am today. At the same the scholarship brings to mind all of the challenges that still lay ahead of me. Lastly the scholarship means encouragement. The scholarship gives me so much encouragement to tackle any challenge that may come my way, to excel in my education, to enrich my community, and to continuously encourage others."',
    },
    {
      childKey: "carri",
      image: "/images/scholarship_winners/carri_in_front_of_shs.jpg",
      header: "2020, Carri Kropka",
      meta: "Kent State University, Nursing",
      description:
        '"I would like to express my sincerest gratitude for being selected as the recipient of the Cindy O’Donnell Memorial Scholarship as this scholarship will directly contribute to continuing my college studies in Nursing at Kent State University! Your assistance enables me to pursue my academic ambitions and this award will not only help me financially, but will also serve as a motivation in doing more for my education. To the Cindy O’Donnell Foundation, thank you so much for noticing my hard work and recognizing my achievements. This foundation is really here to support families who are struck with a hard time and they truly treat you like a family!"',
    },
    {
      childKey: "jacob",
      image: "/images/scholarship_winners/jacob_with_family.jpg",
      header: "2019, Jacob Wharry",
      meta: "West Virginia University, Petroleum Engineering",
      description:
        '"The Cindy O’Donnell Foundation has played an important part in furthering my education. Not only did they help support me financially, but Patrick also reached out to me several times during my first year to check on me and make sure there was nothing that myself or family needed. This scholarship as helped me focus on school and not financial aid. My family and I will be forever thankful for the kindness and generosity that has been shown to us from the Cindy O’Donnell Foundation. I hope one day I can return their generosity."',
    },
  ];
  return (
    <Container text style={{ marginTop: "5%", marginBottom: "5%" }}>
      <Container style={{ textAlign: "center" }}>
        <Item.Image size="large" src="/images/scholarship.jpg" />
      </Container>
      <Container style={{ textAlign: "center", marginTop: "5%" }}>
        <Item>
          <Item.Content>
            <Item.Header as="h2">
              The Cindy O’Donnell Memorial Scholarship
            </Item.Header>
            <Item.Description
              style={{
                marginTop: "2.5%",
                marginBottom: "1.5%",
              }}
            >
              <p>
                The Cindy O’Donnell Foundation is pleased to announce the
                creation of the Cindy O’Donnell Memorial Scholarship fund in
                honor of the late Cindy O’Donnell. In the Spring of each year,
                one very deserving Scholar Athlete from Shadyside High School
                will be awarded a scholarship to advance their academic career
                at the school of their choosing. In order to qualify for
                consideration, the applicant requirements are as follows:
              </p>
              <Grid
                style={{
                  textAlign: "left",
                  marginBottom: "2.5%",
                }}
                stackable
                centered
              >
                <Grid.Column width={2} />
                <Grid.Column width={12}>
                  <List bulleted>
                    <List.Item>
                      Has been accepted into a 4 year program at an institute
                      for higher learning
                    </List.Item>
                    <List.Item>
                      Demonstrates financial need through FAFSA application
                    </List.Item>
                    <List.Item>
                      Must have lettered in at least 1 varsity sport
                    </List.Item>
                    <List.Item>Maintains a 3.0 GPA or higher</List.Item>
                    <List.Item>ACT score of 18 or higher</List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column width={2} />
                {/* ----------------------------------------------------------- */}
                {/* Comment out when the scholarship application period is over 
                <List>
                  <List.Item style={{ fontSize: "1.25rem" }}>
                    <List.Icon name="file alternate outline" />
                    <List.Content>
                      <a href={scholarship} download>
                        Click to download the 2023 application
                      </a>
                    </List.Content>
                  </List.Item>
                </List>
                */}
                {/* ----------------------------------------------------------- */}
              </Grid>
            </Item.Description>
          </Item.Content>
        </Item>
        <Divider hidden />
        <Divider />
        <Divider hidden />
        <h2>Previous Winners</h2>
        <Item.Group>
          {scholarshipWinners.map((winner) => {
            return (
              <Item
                id={winner.childKey}
                key={winner.childKey}
                style={{
                  marginBottom: "5%",
                  textAlign: "center",
                }}
              >
                <Item.Image size="medium" src={winner.image} />
                <Item.Content>
                  <Item.Header>{winner.header}</Item.Header>
                  <Item.Meta>{winner.meta}</Item.Meta>
                  <Item.Description>{winner.description}</Item.Description>
                </Item.Content>
              </Item>
            );
          })}
        </Item.Group>
      </Container>
    </Container>
  );
}
